import Dashboard from '@/views/Dashboard.vue';
import NotFoundComponent from '@/components/app/NotFound.vue';

import { createRouter, createWebHistory } from 'vue-router';
import { APP_ROUTE_NAMES } from '@/helpers/enums';

import { getLocalItem } from '@/helpers/storage';

const routes = [
  {
    path: '/account-deletion',
    name: APP_ROUTE_NAMES.accountDeletion,
    component: () => import('@/views/AccountDeletion.vue'),
  },
  {
    path: '/',
    name: APP_ROUTE_NAMES.dashboard,
    component: Dashboard,
    children: [
      {
        path: '',
        name: APP_ROUTE_NAMES.default,
        component: () => import('@/modules/accountInfo/MobileAccountInfo.vue'),
      },
      {
        path: 'market/:name?',
        name: APP_ROUTE_NAMES.dashboard,
        component: Dashboard,
      },
      {
        path: 'markets',
        name: APP_ROUTE_NAMES.markets,
        component: () => import('@/modules/markets/Markets.vue'),
      },
      {
        path: 'positions',
        name: APP_ROUTE_NAMES.positions,
        component: () => import('@/modules/positions/MobilePositions.vue'),
      },
      {
        path: 'account',
        name: APP_ROUTE_NAMES.account,
        component: () => import('@/modules/accountInfo/MobileAccountInfo.vue'),
      },
      {
        path: 'chart/:name',
        name: APP_ROUTE_NAMES.chart,
        component: {},
      },
      {
        path: 'login',
        name: APP_ROUTE_NAMES.login,
        components: {
          default: {},
          Login: () => import('@/modules/user/Login.vue'),
        },
      },
      {
        path: 'DoYouKnowThat',
        name: APP_ROUTE_NAMES.signup,
        components: {
          default: {},
          Login: () => import('@/modules/user/DoYouKnowThat.vue'),
        },
      },
      {
        path: '/confirmation',
        name: APP_ROUTE_NAMES.confirmation,
        component: () => import('@/modules/user/Empty.vue'), // empty layout
      },
      {
        path: '/index',
        name: APP_ROUTE_NAMES.mbIndex,
        component: () => import('@/modules/user/Empty.vue'), // empty layout
      },
    ],
  },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   components: {
  //     default: Dashboard,

  //   }
  // },
  {
    path: '/:catchAll(.*)',
    component: NotFoundComponent,
    name: APP_ROUTE_NAMES.notFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return (
      savedPosition || getLocalItem(`mobileScrollPositions.${to.name}`) || null
    );
  },
});

export default router;
