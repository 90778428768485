import debounce from 'lodash/debounce';

class AsyncLock {
  constructor() {
    this.awaitable = Promise.resolve();
  }

  async acquire() {
    let resolver;
    const awaitable = this.awaitable;
    this.awaitable = new Promise((x) => {
      resolver = x;
    });
    await awaitable;
    return resolver;
  }
}

export const createAsyncLock = () => {
  const lock = new AsyncLock();
  return async (fn) => {
    const release = await lock.acquire();
    try {
      return await fn();
    } finally {
      release();
    }
  };
};

export const createDebounceWithLockFactory = () => {
  const lock = new AsyncLock();
  return (ms, fn) =>
    debounce(async (...args) => {
      const release = await lock.acquire();
      try {
        return await fn.apply(this, args);
      } finally {
        release();
      }
    }, ms);
};

// test:

// let i = 0;
// const log = console.log.bind(console, '#lock');
// const withLock = createAsyncLock();
//
// async function startPushSchedulerInternal() {
//   i++;
//   log('test enter', i);
//
//   let x = i;
//
//   await withLock(async () => {
//     log('test internal start ', x);
//     log('test internal sleep ', x);
//     await sleep(1000);
//     log('test internal UNsleep ', x);
//     log('test internal int end ', x);
//
//     log('test out', x);
//   });
// }
//
// async function testPushScheduler() {
//   await Promise.all([
//     startPushSchedulerInternal(),
//     startPushSchedulerInternal(),
//     startPushSchedulerInternal(),
//   ]);
//   log('all done push');
// }
//
// testPushScheduler();
