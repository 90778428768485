const types = {
  CHANGE_GRAPH_TYPE: 'CHANGE_GRAPH_TYPE',
  SET_GRAPH_VISIBILITY: 'SET_GRAPH_VISIBILITY',
};

const getInitialState = () => ({
  currentGraphType: 'MID',
  isGraphVisible: false,
});

const getters = {
  getCurrentGraphType: (state) => state.currentGraphType,
  isGraphVisible: (state) => state.isGraphVisible,
};

const mutations = {
  [types.CHANGE_GRAPH_TYPE](state, type) {
    state.currentGraphType = type;
  },
  [types.SET_GRAPH_VISIBILITY](state, value) {
    console.debug(
      '#graph #setGraphVisibility #isGraphVisible commit',
      'value:',
      value,
      'was isGraphVisible:',
      this.state.graph.isGraphVisible
    );
    state.isGraphVisible = value;
  },
};

const actions = {
  changeGraphType({ commit }, type) {
    commit(types.CHANGE_GRAPH_TYPE, type);
  },

  setGraphVisibility({ commit }, value) {
    commit(types.SET_GRAPH_VISIBILITY, value);
  },
};

export default {
  namespaced: true,
  state: getInitialState(),
  mutations,
  actions,
  getters,
};
