import EventEmitter from 'events';
import { createDebounceWithLockFactory } from '@/helpers/asyncLock';
import { sleep } from '@/helpers/retry';

const eventEmitter = new EventEmitter();

const debounceWithLock = createDebounceWithLockFactory();

let isShown = false;

const isAvailable = 'virtualKeyboard' in navigator;

if (isAvailable) {
  // [vadim] : this is a workaround for the issue when the keyboard is hidden but height is not 0
  const doubleCheck = debounceWithLock(300, async () => {
    const height = navigator.virtualKeyboard.boundingRect.height;

    console.debug('doubleCheck #mobileKeyboard', 'height:', height);
    if (height > 0) {
      eventEmitter.emit('show', height);
    } else {
      eventEmitter.emit('hide');
    }
  });

  navigator.virtualKeyboard.addEventListener(
    'geometrychange',
    debounceWithLock(20, async (event) => {
      // the height is not real sometimes, so may need to double check
      let height = event.target.boundingRect.height;

      // if state is wrong, let's wait a bit and check again - to avoid doubleCheck in 300ms
      const needSleep = (height > 0 && isShown) || (height === 0 && !isShown);
      if (needSleep) {
        console.debug(
          'geometrychange #mobileKeyboard, needSleep:',
          needSleep,
          'height:',
          height
        );

        await sleep(30);
        let newH = navigator.virtualKeyboard.boundingRect.height;

        if (newH !== height) {
          console.debug(
            'geometrychange #mobileKeyboard, new height:',
            newH,
            'old height:',
            height
          );
          height = newH;
        }
      }

      if (height > 0) {
        console.debug(
          'SHOW geometrychange #mobileKeyboard, lastIsShown:',
          isShown,
          'height:',
          height
        );
        if (!isShown) {
          isShown = true;
          eventEmitter.emit('show', height);
        } else {
          console.debug(
            'already shown geometrychange #mobileKeyboard, will double heck in 300ms, lastIsShown:',
            isShown,
            'height:',
            height
          );

          // do not await
          doubleCheck();
        }
      } else {
        console.debug(
          'HIDE geometrychange #mobileKeyboard, lastIsShown:',
          isShown
        );
        if (isShown) {
          isShown = false;
          eventEmitter.emit('hide');
        } else {
          console.debug(
            'already hidden geometrychange #mobileKeyboard, will double heck in 300ms, lastIsShown:',
            isShown
          );

          // do not await
          doubleCheck();
        }
      }
    })
  );
}

export const mobileKeyboard = {
  isAvailable: isAvailable,
  show() {
    if (isAvailable) {
      navigator.virtualKeyboard.show();
    }
  },

  hide() {
    if (isAvailable) {
      navigator.virtualKeyboard.hide();
    }
  },
  disableBuiltinBehavior() {
    if (isAvailable) {
      navigator.virtualKeyboard.overlaysContent = true;
    }
  },

  height() {
    if (isAvailable) {
      return navigator.virtualKeyboard.boundingRect.height;
    }

    return 0;
  },

  enableBuiltinBehavior() {
    if (isAvailable) {
      navigator.virtualKeyboard.overlaysContent = false;
    }
  },

  subscribeToKeyboardEvents(onShow, onHide) {
    eventEmitter.on('show', onShow);
    eventEmitter.on('hide', onHide);

    const unsubscribe = () => {
      eventEmitter.off('show', onShow);
      eventEmitter.off('hide', onHide);
    };

    return unsubscribe;
  },
};
