import { connApp, getErrorDetails } from '@/helpers/signalR';
import store from '@/store';
import { createAsyncLock } from '@/helpers/asyncLock';

const sendUiSettings_lock = createAsyncLock();

export const sendUISettingsWithLock = async (from, newUiSettings) => {
  if (!store.getters['auth/isLoggedIn']) {
    return;
  }

  return await sendUiSettings_lock(async () => {
    const oldSettings = { ...store.getters['settings/uiSettings'] };
    const objToSave = Object.assign(oldSettings, newUiSettings);

    console.debug(
      'updateUserSettings FROM LOCK #settings api.js',
      'from:',
      from,
      '\n',
      'objToSave:',
      objToSave
    );

    const params = {
      UISettings: JSON.stringify(objToSave),
    };

    try {
      await connApp.invokeWithRetry('UpdateUserInfo', params);
    } catch (error) {
      return getErrorDetails(error);
    }
  });
};

const loadSettings_Lock = createAsyncLock();

export async function loadAllSettings(from) {
  return await loadSettings_Lock(async () => {
    try {
      console.debug('#loadAllSettings #settings api.js, from:', from);
      const settings = await connApp.invokeWithRetry('GetUser');

      if (settings?.user) {
        await store.dispatch('settings/setUserSettings', settings.user);
      } else {
        console.error(
          '#loadAllSettings settings.user is not defined from GetUser'
        );
      }
    } catch (e) {
      console.error(e);
    }
  });
}

const saveSettings_lock = createAsyncLock();

export async function sendUiSettingsAndLoadAllSettings(newUiSettings) {
  return await saveSettings_lock(async () => {
    newUiSettings = newUiSettings?.uiSettings || newUiSettings;

    try {
      await sendUISettingsWithLock('updateAndSetUserSettings', newUiSettings);
      await loadAllSettings('sendUiSettingsAndLoadAllSettings');

      // check if settings are updated as we expect
      // compare with the settings with the sent data
      {
        const currentUiSettings = store.getters['settings/uiSettings'];

        for (const key in newUiSettings) {
          if (newUiSettings[key] !== currentUiSettings[key]) {
            console.error(
              '#settings uiSettings not updated as expected after sendUiSettingsAndLoadAllSettings',
              `[${key}] expected: ${newUiSettings[key]}, actual: ${currentUiSettings[key]}`
            );
          }
        }
      }
    } catch (error) {
      console.error(
        `Unable to update uiSettings ${newUiSettings}, error: ${error.stack}`
      );
    }
  });
}
