import EventEmitter from 'events';
import { createDebounceWithLockFactory } from '@/helpers/asyncLock';

const emitterGroups = {};

const FOCUS_EVENT = 'focused';
const FOCUS_OUT_EVENT = 'focusOut';

const getEmitter = (groupName) => {
  if (!groupName) {
    console.error('groupName is required');
    throw new Error('groupName is required');
  }

  if (!emitterGroups[groupName]) {
    emitterGroups[groupName] = new EventEmitter();
  }
  return emitterGroups[groupName];
};

export const subscribeToInputFocusEvents = (
  groupName,
  onFocused,
  onFocusOut
) => {
  const eventEmitter = getEmitter(groupName);

  eventEmitter.on(FOCUS_EVENT, onFocused);
  eventEmitter.on(FOCUS_OUT_EVENT, onFocusOut);

  const unsubscribe = () => {
    eventEmitter.off(FOCUS_EVENT, onFocused);
    eventEmitter.off(FOCUS_OUT_EVENT, onFocusOut);
  };

  return unsubscribe;
};

export const subscribeThrottledToInputFocusEvents = (
  groupName,
  onFocused,
  onFocusOut
) => {
  let counter = 0;

  const debounceWithLock = createDebounceWithLockFactory();

  const onFocusedInternal = debounceWithLock(5, async () => {
    counter++;
    if (counter > 1) {
      console.debug(
        'onFocus #subscribeThrottledToInputFocusEvents #throttle RETURN, counter:',
        counter
      );
      return;
    }

    await onFocused();
  });

  const onFocusOutInternal = debounceWithLock(60, async () => {
    counter--;
    if (counter > 0) {
      console.debug(
        'onFocusOUT #subscribeThrottledToInputFocusEvents #throttle RETURN, counter:',
        counter
      );
      return;
    }

    await onFocusOut();
  });

  const unsubscribe = subscribeToInputFocusEvents(
    groupName,
    onFocusedInternal,
    onFocusOutInternal
  );

  return () => {
    unsubscribe();
    onFocusedInternal.cancel();
    onFocusOutInternal.cancel();
  };
};

export const clearAllSubscriptions = (groupName) => {
  const eventEmitter = getEmitter(groupName);
  eventEmitter.removeAllListeners();
  emitterGroups[groupName] = null;
};

export const inputFocused = (groupName) => {
  const eventEmitter = getEmitter(groupName);
  eventEmitter.emit(FOCUS_EVENT);
};

export const inputFocusedOut = (groupName) => {
  const eventEmitter = getEmitter(groupName);
  eventEmitter.emit(FOCUS_OUT_EVENT);
};

export const useFocusEvents = (groupName) => {
  const onFocused = () => {
    inputFocused(groupName);
  };

  const onFocusOut = () => {
    inputFocusedOut(groupName);
  };

  return {
    onFocused,
    onFocusOut,
  };
};
