<template>
  <Modal
    :stop-mobile-max-height="true"
    :hide-back-controls="true"
    :hide-close="true"
    :close-outside="false"
    to-top
    @close="onClose"
  >
    <Card
      title="Bounty Profits"
      description="Choose currency to get bounty profits"
      class="card"
      :body-class="'card-full'"
    >
      <div
        :class="{
          standalone: wd.navigator.standalone || isTelegram,
          webBottomNull: activeIndex === -1,
        }"
        class="new-bounty"
      >
        <template v-if="activeIndex > -1">
          <div class="new-bounty__items-item total">
            {{ tokensList[activeIndex] }}
          </div>
          <div class="new-bounty__disclaimer">
            Profits from trading with Bounty will be
            <span>sent to your self-custodial wallet in USDT-MATIC only.</span>
            This choice cannot be changed.
          </div>
          <div class="new-bounty__disclaimer">
            IMPORTANT: DXS operates on the blockchain using smart contracts. To
            begin withdrawing,
            <span
              >you'll need to pay a setup fee to miners and make an initial
              deposit.</span
            ><span class="learn"> Learn more</span>
          </div>
        </template>
        <template v-else>
          <div class="new-bounty__items">
            <div
              class="new-bounty__items-item"
              :class="{ active: activeIndex === ndx }"
              v-for="(item, ndx) in tokensList.slice(0, sliceCount)"
              :key="ndx"
              @click="activeIndex = ndx"
            >
              {{ item }}
            </div>
            <div
              v-if="sliceCount <= 3"
              class="new-bounty__items-item noclick"
              @click="sliceCount = tokensList.length"
            >
              + Other Asset
            </div>
          </div>
          <div
            class="new-bounty__burn"
            :class="{ turnBountyPoints: uiSettings.turnBountyPoints }"
            @click="toggleTurn"
          >
            {{
              uiSettings.turnBountyPoints
                ? 'TURN ON MY BOUNTY'
                : 'TURN OFF MY BOUNTY'
            }}
          </div>
        </template>
      </div>
      <Button
        v-if="activeIndex > -1"
        text="CONFIRM"
        :loading="startLoading"
        type="secondary"
        :class="{
          standalone: wd.navigator.standalone || isTelegram,
        }"
        class="new-bounty__btn"
        @click="confirm"
      />
    </Card>
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';
// import { usdBsvFormatAmount } from '@/helpers/utils';
// import { useModals } from '@/modules/modals/api';
// import { connApp, getErrorDetails } from '@/helpers/signalR';
import '@/assets/icons/check';
// import notify from '@/plugins/notify';
import { sendUiSettingsAndLoadAllSettings } from '@/modules/settings/api';
import authMixin from '@/mixins/auth.mixin';
import useAuth from '@/compositions/useAuth';

// const FIORIN_HOST = process.env.VUE_APP_FIORIN_HOST;

export default {
  mixins: [authMixin],
  components: {
    Modal,
    Card,
    Button,
  },
  setup() {
    const auth = useAuth();
    const activeIndex = ref(-1);
    const sliceCount = ref(3);
    // const tokensList = ref(['USDT-ERC20', 'USDT-TRC20', 'USDT-MATIC', 'BSV']);
    const store = useStore();
    const startLoading = ref(false);
    // const { showModal, modalsByName } = useModals();
    // const confirmLoading = ref(false);

    const tokensList = computed(() => {
      if (!auth.isFiorin.value) {
        return [];
      }
      const tokens = store.getters['fiorin/activeTokens'];
      return tokens?.map((item) => item.name) ?? [];
    });

    const learnMore = async () => {
      // window.open(
      //   'https://faq.dxs.app/getting-started/bounty-trading',
      //   '_blank'
      // );
    };

    const uiSettings = computed(() => {
      return store.getters['settings/uiSettings'];
    });

    const turnFlag = ref(false);

    const toggleTurn = async () => {
      if (turnFlag.value) {
        return;
      }

      turnFlag.value = true;
      await sendUiSettingsAndLoadAllSettings({
        turnBountyPoints: !uiSettings.value.turnBountyPoints,
      });
      setTimeout(() => {
        turnFlag.value = false;
      }, 1000);
    };

    const isTelegram = computed(() =>
      Boolean(store.getters['telegram/instance'])
    );

    const onClose = () => {
      console.log('gg');
      if (activeIndex.value > -1) {
        activeIndex.value = -1;
      }
    };

    const confirm = () => {};

    onMounted(async () => {
      await sendUiSettingsAndLoadAllSettings({
        needSetCurrencyBountyProfits: true,
      });
    });

    return {
      startLoading,
      wd: window,
      isTelegram,
      uiSettings,
      tokensList,
      activeIndex,
      sliceCount,
      toggleTurn,
      learnMore,
      onClose,
      confirm,
    };
  },
};
</script>
<style lang="scss" scoped>
.new-bounty {
  min-height: 400px;
  font-family: Gotham_Pro_Regular;
  padding-top: 4px;

  max-height: calc(100% - 145px);
  overflow-y: auto;
  margin-bottom: 60px;
  padding-right: 20px;
  padding-left: 20px;
  box-sizing: border-box;
  overflow-x: hidden;

  &.standalone {
    @media screen and (max-width: 410px) {
      max-height: calc(100% - 165px);
    }
  }

  @media screen and (min-width: 1024px) {
    max-height: calc(100vh - 360px) !important;
    padding-right: 40px;
    padding-left: 40px;

    &.webBottomNull {
      margin-bottom: 0;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    overflow: hidden;
  }

  &__items-item {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
    color: #fff;
    background: #262c3c;
    padding: 0 20px;
    font-family: Gotham_Pro_Medium;
    margin-bottom: 1px;
    cursor: pointer;

    &.active {
      background: #fff;
      color: #000;
    }

    &.noclick {
      color: rgba(255, 255, 255, 0.6);
    }

    &.total {
      margin-left: -20px;
      margin-bottom: 25px;
      width: calc(100% + 40px);
      padding: 0 30px;

      @media screen and (min-width: 1024px) {
        margin-left: -40px;
        width: calc(100% + 80px);
        padding: 0 50px;
      }
    }
  }

  &__disclaimer {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.6);
    font-family: Gotham_Pro_Regular;
    line-height: 20px;

    &:last-child {
      margin-top: 24px;
    }

    span {
      font-family: Gotham_Pro_Medium;
      color: rgba(255, 255, 255, 0.6);
    }

    .learn {
      color: #fff;
      cursor: pointer;
    }
  }

  &__field {
    width: calc(100% + 15px);
    margin-left: -6px;
    margin-bottom: 24px;
  }

  &__burn {
    margin-top: 24px;
    padding: 0 22px;
    border-radius: 6px;
    height: 44px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(203, 72, 64, 0.25);
    color: #cb4840;
    font-size: 15px;
    font-family: Gotham_Pro_Medium;
    margin-bottom: 28px;
    cursor: pointer;

    &.turnBountyPoints {
      background-color: rgba(0, 173, 145, 0.25);
      color: rgba(0, 173, 145, 1);
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__btn {
    margin-top: 30px;
    position: absolute;
    bottom: 50px;
    left: 0;
    width: 100%;

    &.standalone {
      bottom: 82px;
    }

    @media screen and (min-width: 1024px) {
      bottom: 0;
    }

    &.more {
      bottom: 60px;
    }
  }
}

body.dark {
}

body.light {
  .new-bounty {
    &__items-item {
      background: #dcdada;
      color: #000;
    }

    &__disclaimer {
      color: #000;

      span {
        color: #444;
      }

      .learn {
        color: #444;
      }
    }
  }
}
</style>
