import { computed } from 'vue';
import store from '@/store';

import { floorUsdNumber } from '@/helpers/utils';

import { POSITION_OPEN_MIN_VAL } from '@/helpers/enums';
import bsvPrice from '@/compositions/bsvPrice';

export default function useMaxAmount(leverageComputed) {
  const isFiorin = computed(() => store.getters['auth/isFiorin']);

  const uiSettings = computed(() => {
    return store.getters['settings/uiSettings'];
  });

  const isNullBounty = computed(() => {
    return isFiorin.value && uiSettings.value.turnBountyPoints;
  });

  const activeMarket = computed(() => {
    return store.getters['markets/activeMarket'];
  });

  const getAccountBalance = computed(
    () => store.getters['user/getAccountBalance']
  );

  const maxAllowedByMarket = computed(() => {
    if (!activeMarket.value) {
      return 0;
    }

    const maxAmountValue = activeMarket.value.max_amount_usd;
    const marketSumAmount = store.getters['positions/getMarketMaxAmount'](
      activeMarket.value.name
    );
    const result = maxAmountValue - marketSumAmount;
    return { amount: result, marketName: activeMarket.value.name };
  });

  const bsvFee = 0.0001; //10000 сатошей

  const maxPositionAmountUsd = computed(() => {
    if (!activeMarket.value) {
      return 0;
    }
    const userBalanceUsd = Math.max(
      getAccountBalance.value.available,
      isNullBounty.value ? 0 : getAccountBalance.value.bounty
    );

    const usdtFee = bsvFee * bsvPrice();
    const maxUserPositionAmount = Math.max(
      0,
      leverageComputed.value * userBalanceUsd - usdtFee
    );

    const marketAvailable = maxAllowedByMarket.value;
    let minVal = Math.min(marketAvailable.amount, maxUserPositionAmount);

    minVal = floorUsdNumber(minVal);
    minVal = Math.max(minVal, 0);

    return {
      amount: minVal,
      marketName: marketAvailable.marketName,
    };
  });

  const maxWalletValue = computed(() => {
    const result = Math.max(
      getAccountBalance.value.available,
      isNullBounty.value ? 0 : getAccountBalance.value.bounty
    );

    return result;
  });

  const enableToOpenPosition = computed(() => {
    const minVal = maxPositionAmountUsd.value.amount;

    if (maxWalletValue.value < POSITION_OPEN_MIN_VAL) {
      return false;
    }

    return +minVal !== 0;
  });

  // const amountTip = computed(() => {
  //   if (!activeMarket.value) {
  //     return '';
  //   }
  //
  //   if (amountStepFlag === 2) {
  //     // return t('order.new.titles.half');
  //     const val = (+halfBalance.value + '').replace(/ /g, '');
  //     if (val <= 0) {
  //       return t('popups.marketInactive.limitReached');
  //     }
  //
  //     return `${t('order.new.titles.half')} ${
  //       (+halfBalance.value + '').replace(/ /g, '') <= 0
  //         ? t('popups.marketInactive.limitReached')
  //         : halfBalance.value
  //     }`;
  //   }
  //
  //   if (amountStepFlag === 3) {
  //     // return t('order.new.titles.quarter');
  //     const val = (+quarterBalance.value + '').replace(/ /g, '');
  //     if (val <= 0) {
  //       return t('popups.marketInactive.limitReached');
  //     }
  //
  //     return `${t('order.new.titles.quarter')} ${
  //       (+quarterBalance.value + '').replace(/ /g, '') <= 0
  //         ? t('popups.marketInactive.limitReached')
  //         : quarterBalance.value
  //     }`;
  //   }
  //
  //   const maxAmountValue = activeMarket.value.max_amount_usd;
  //
  //   if (!+maxAmountValue) {
  //     return t('popups.marketInactive.limitReached');
  //   }
  //
  //   // if (amount && amount.length) {
  //   //   return `${
  //   //     toCurrencyFormat(
  //   //       amount /
  //   //         (isFiorin.value
  //   //           ? 1
  //   //           : bsvPrice.value),
  //   //       null,
  //   //       fiorinMode ? 2 : 5
  //   //     ) || 0
  //   //   } ${fiorinMode ? 'USD' : 'BSV'}`;
  //   // }
  //
  //   const minVal = maxPositionAmountUsd.value;
  //
  //   if (maxWalletValue.value < POSITION_OPEN_MIN_VAL) {
  //     return t('popups.marketInactive.walletDepleted');
  //   }
  //
  //   if (+minVal === 0) {
  //     return t('popups.marketInactive.limitReached');
  //   }
  //   return `${t('order.new.titles.max')} ${
  //     +minVal.replace(/ /g, '') < 0
  //       ? t('popups.marketInactive.limitReached')
  //       : minVal
  //   }`;
  // });

  return {
    maxPositionAmountUsd,
    maxWalletValue,
    enableToOpenPosition,
    // amountTip,
  };
}
