<template>
  <Modal stop-mobile-max-height to-top @close="onClose">
    <Card
      :title="$t('tradingSession.titles.telegramConnect').toUpperCase()"
      class="card"
    >
      <div class="recovery-modal">
        <div v-if="requestingState === LOAD_STATE.fetching">
          <div class="status-loader">
            <Loader color="black" />
          </div>
        </div>
        <div v-if="requestingState === LOAD_STATE.success">
          <div v-if="canConnect" class="nomargin">
            You will redirect to Telegram bot to link your account.
          </div>
          <div v-else class="nomargin">
            Telegram bot already connected to your account
          </div>
        </div>
      </div>
    </Card>
    <div
      class="btns"
      :class="{
        standalone: $isStandalone || $isTelegram,
      }"
    >
      <Button
        v-if="canConnect"
        :text="$t('popups.telegramConnect.button')"
        :loading="requestingState === LOAD_STATE.fetching"
        :disabled="requestingState === LOAD_STATE.fetching"
        @click="redirectToTelegram"
        type="secondary"
      />
    </div>
  </Modal>
</template>

<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import { computed, onMounted, ref } from 'vue';
import authMixin from '@/mixins/auth.mixin';
import screenMixin from '@/mixins/screen.mixin';
import Loader from '../ui/Loader.vue';
import { connApp } from '@/helpers/signalR';

const EVENTS = {
  close: 'close',
};

const LOAD_STATE = {
  idle: 'idle',
  fetching: 'fetching',
  success: 'success',
};

export default {
  mixins: [authMixin, screenMixin],
  components: { Modal, Card, Button, Loader },
  emits: [EVENTS.close],
  setup(_, { emit }) {
    const requestingState = ref(LOAD_STATE.idle);
    const response = ref();
    const canConnect = computed(
      () =>
        requestingState.value === LOAD_STATE.success &&
        !response.value.telegramUsername
    );

    onMounted(() => fetchStatus());

    const fetchStatus = () => {
      requestingState.value = LOAD_STATE.fetching;

      connApp
        .invokeImmediately('GetUserTelegramStatus')
        .then((data) => {
          console.log('STATUS', data);
          return data;
        })
        .then((data) => {
          response.value = data;
        })
        .finally(() => {
          requestingState.value = LOAD_STATE.success;
        });
    };

    const handleFocus = () => {
      window.removeEventListener('focus', handleFocus);

      fetchStatus();
    };

    const redirectToTelegram = () => {
      window.open(
        `https://t.me/${response.value.botUserName}?start=${response.value.userTelegramToken}`,
        '_blank'
      );

      window.addEventListener('focus', handleFocus);
    };

    const onClose = () => {
      emit(EVENTS.close);
    };

    console.log('STATUS', { canConnect: canConnect.value });

    return {
      onClose,
      redirectToTelegram,
      response,
      requestingState,
      canConnect,
      LOAD_STATE,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.btn--loading.btn--disabled:hover {
  background: #262a39;
}

.status-loader {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-bottom: 55px;
}

.recovery-modal {
  height: auto;
  max-height: calc(100% - 102px);
  width: calc(100% + 40px);
  margin-left: -20px;
  overflow-y: auto;
  margin-bottom: 45px;
  padding: 0 20px;
  box-sizing: border-box;
  font-family: Gotham_Pro_Regular;
  line-height: 20px;

  @media screen and (min-width: 1024px) {
    max-height: calc(100vh - 200px) !important;
  }

  div {
    margin-top: 25px;

    &.nomargin {
      margin: 0;
      margin-bottom: 15px;
    }

    h4 {
      font-family: Gotham_Pro_Bold !important;
    }
  }

  &__issue {
    display: flex;
    align-items: center;
    margin-top: 10px;

    &.last {
      margin-bottom: 20px;
    }

    .check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      margin-top: 0;
      border: 1px solid #ccc;
    }

    .label-description {
      margin-top: 1px;
      margin-left: 10px;
    }
  }

  p {
    &.underline {
      text-decoration-line: underline;
    }
  }
}

.btns {
  width: 100%;
  margin-top: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    bottom: 50px;

    &.standalone {
      bottom: 82px;
    }
  }

  a {
    text-decoration: none;
  }
}
</style>
