import store from '@/store';
import { WalletNames } from '@/wallets/common';
import FiorinConnector from '@/wallets/Fiorin';
import getFiorinEventHandlers from '@/store/modules/auth/fiorinHandlers';
import { isFiorinStable } from '@/config/constants';
import throttle from 'lodash/throttle';

function createFiorinConnector(account) {
  return new FiorinConnector(getFiorinEventHandlers(), {
    accessToken: account.accessToken,
    appId: account.appId,
    id: account.id,
  });
}

let _connector = null;

let lastBalanceLogs = null;
const printLogsThrottled = throttle(() => {
  if (lastBalanceLogs) {
    lastBalanceLogs.forEach((log) => {
      console.log(...log);
    });
    lastBalanceLogs = null;
  }
}, 120_000);

const getConnector = () => {
  if (!_connector) {
    throw new Error('Fiorin connector is not initialized');
  }

  return _connector;
};

const fetchTonUsdtBalanceThrottled = throttle(async () => {
  console.debug('Fetching TONUSDT balance #fiorin #fetchTonUsdtBalance');
  await getConnector().fetchBalanceTonUsdt();
}, 5_000);

const assertFiorin = () => {
  if (!store.getters['auth/isFiorin']) {
    console.debugError(
      'Accessing the fiorin-related fields when not authenticated  or using other wallet #wallet #getters #ifFiorin',
      'provider:',
      store.getters['auth/provider']
    );

    throw new Error('Not Fiorin');
  }
};

const validateToken = (token) => {
  if (!token) {
    throw new Error('token is null #wallet #mutations #setBalances');
  } else {
    if (!token.tokenId) {
      throw new Error('token.tokenId is empty #wallet #mutations #setBalances');
    }
    if (!token.currency) {
      throw new Error(
        'token.currency is empty #wallet #mutations #setBalances'
      );
    }

    if (!token.name) {
      throw new Error('token.name is empty #wallet #mutations #setBalances');
    }
  }
};

const makeLogs = (newBalances) => {
  const logs = [];

  logs.push(['#balance #wallet PendingBalance:', newBalances.pendingBalance]);
  logs.push([
    `
      #balance;
      #wallet;
      BountyBalance: ${JSON.stringify(newBalances.bountyBalance)}
      `,
  ]);

  logs.push([
    '#balance #wallet TokenWithMaxBalance',
    newBalances.tokenWithMaxBalance?.tokenId,
  ]);

  logs.push([
    '#balance #wallet ActiveTokens:\n',
    newBalances.activeTokens.map((t) => JSON.stringify(t)).join('\n\n'),
  ]);

  logs.push(['#balance #wallet TotalAssetsUsd:', newBalances.totalAssetsUsd]);

  // todo: [Vadim] log only if values are changed
  // let log = [
  //   `| ${+new Date()} |`,
  //   `TMBA: ${newBalances.tokenWithMaxBalance.amount}`,
  //   `Bb: ${newBalances.bountyBalance}`,
  //   `PB: ${newBalances.pendingBalance}`,
  //   `TAU: ${newBalances.totalAssetsUsd}`,
  // ].join('\n');

  // logs.push(log);
};

const types = {
  SET_FIORIN_LAST_TX_ID: 'SET_FIORIN_LAST_TX_ID',
};

const state = {
  userName: '',
  email: '',
  paymail: null,
  targetWeb3Provider: null,

  balances: {
    totalAssetsUsd: 0,
    activeTokens: [],
    pendingBalance: 0,
    bountyBalance: {},
    // [Vadim] store exactly whole object tokenWithMaxBalance,
    // do not hope to store only id to get from activeTokens
    // because when user has no tokens, we get empty activeTokens
    // but tokenWithMaxBalance is not empty
    tokenWithMaxBalance: {
      tokenId: '',
      currency: '',
      name: '',
      amount: 0,
      amountUsd: 0,
    },
  },

  fiorinLastTxId: '',

  // [Vadim] do not put into 'balances' obj
  balancesLog: [],
};

const getters = {
  balancesLog: (state) => state.balancesLog,

  // todo: rename fiorinLastTx -> fiorinLastTxId
  fiorinLastTx: (state) => state.fiorinLastTxId,

  isFiorinAsProxy: (state) => {
    assertFiorin();
    return !!state.targetWeb3Provider;
  },

  activeTokens: (state) => {
    assertFiorin();
    return state.balances.activeTokens;
  },

  totalAssetsAmountUsd: (state) => {
    assertFiorin();
    return state.balances.totalAssetsUsd ?? 0;
  },

  pendingUsd: (state) => {
    assertFiorin();
    return state.balances.pendingBalance;
  },

  userName: (state) => {
    assertFiorin();
    return state.userName;
  },

  paymail: (state) => {
    assertFiorin();
    return state.paymail;
  },

  bountyBalanceUsd: (state) => {
    assertFiorin();
    return state.balances.bountyBalance?.amountUsd;
  },

  targetWeb3Provider: (state) => {
    assertFiorin();
    return state.targetWeb3Provider;
  },

  isTon: (state) => {
    assertFiorin();
    return state.targetWeb3Provider.toLowerCase() === 'ton';
  },
};

const mutations = {
  clear(state) {
    state.userName = '';
    state.email = '';
    state.fiorinLastTxId = '';
    state.paymail = null;
    state.targetWeb3Provider = null;
    state.balancesLog = [];
    state.balances.pendingBalance = 0;
    state.balances.activeTokens = [];
    state.balances.tokenWithMaxBalance = {};
    state.balances.totalAssetsUsd = 0;
    state.balances.bountyBalance = {};
  },

  init(state, { paymail, userName, targetWeb3Provider, email }) {
    if (!paymail) {
      throw new Error('No paymail passed to init #fiorin #mutations #init');
    }

    if (!userName) {
      throw new Error('No userName passed to init #fiorin #mutations #init');
    }

    if (!targetWeb3Provider) {
      throw new Error(
        'No targetWeb3Provider passed to init #fiorin #mutations #init'
      );
    }

    state.paymail = paymail;
    state.userName = userName;
    state.targetWeb3Provider = targetWeb3Provider;
    //email can be null, for example, if it's metamask internally
    state.email = email;
  },

  [types.SET_FIORIN_LAST_TX_ID](state, id) {
    console.debug('Setting fiorinLastTxId #fiorin #mutations', id);
    state.fiorinLastTxId = id;
  },

  setBalancesTonUsdt(state, usdtToken) {
    validateToken(usdtToken);

    let newBalances = {};
    const activeTokens = [usdtToken];
    newBalances.activeTokens = activeTokens;
    newBalances.tokenWithMaxBalance = usdtToken;
    newBalances.totalAssetsUsd = usdtToken.amountUsd;

    newBalances = { ...state.balances, ...newBalances };
    state.balances = newBalances;

    const logs = makeLogs(newBalances);
    lastBalanceLogs = logs;
    printLogsThrottled();
  },

  setBalances(
    state,
    { tokenWithMaxBalance, activeTokens, bountyBalance, pendingBalance }
  ) {
    validateToken(tokenWithMaxBalance);

    if (typeof bountyBalance !== 'object') {
      throw new Error(
        `bountyBalance should be an object #wallet #mutations #setBalances, but got: ${typeof bountyBalance}, value: |${JSON.stringify(
          bountyBalance
        )}|`
      );
    } else {
      if (typeof bountyBalance.amountUsd !== 'number') {
        throw new Error(
          `bountyBalance.amount is not a number #wallet #mutations #setBalances, but got: ${typeof bountyBalance.amountUsd}, value: |${JSON.stringify(
            bountyBalance.amountUsd
          )}|`
        );
      }
    }

    if (typeof pendingBalance !== 'number') {
      throw new Error(
        `pendingBalance should be a number #wallet #mutations #setBalances, but got type: ${typeof pendingBalance}, value:|${JSON.stringify(
          pendingBalance
        )}|]`
      );
    }

    if (!Array.isArray(activeTokens)) {
      throw new Error(
        `activeTokens should be an array #wallet #mutations #setBalances, but got type: ${typeof activeTokens}, value:|${JSON.stringify(
          activeTokens
        )} |]`
      );
    }

    // const fakeToken = {
    //   tokenId: 'b00498d6e717c1117af86abe37fecf13e45ff358',
    //   name: 'USDT',
    //   amount: 10,
    //   amountUsd: 10,
    //   currency: 'Usdt',
    //   order: 1,
    // };
    //
    // activeTokens.push(fakeToken);

    let newBalances = {};

    if (state.targetWeb3Provider.toLowerCase() !== 'ton') {
      newBalances.tokenWithMaxBalance = tokenWithMaxBalance;

      newBalances.activeTokens = activeTokens;
      const totalAssetsUsd = activeTokens.reduce((prev, item) => {
        return prev + item.amountUsd;
      }, 0);
      newBalances.totalAssetsUsd = totalAssetsUsd;
    }

    newBalances.bountyBalance = bountyBalance;
    newBalances.pendingBalance = pendingBalance;

    // newBalances.tokenWithMaxBalance = fakeToken;

    // state.balancesLog.push(log);
    newBalances = { ...state.balances, ...newBalances };
    state.balances = newBalances;

    const logs = makeLogs(newBalances);
    lastBalanceLogs = logs;
    printLogsThrottled();
  },
};

const actions = {
  async initAuth(_, account) {
    if (account.provider !== WalletNames.fiorin) {
      throw new Error(
        'Invalid provider. #fiorin #initAuth Expected Fiorin, got ' +
          account.provider
      );
    }

    if (!account.accessToken) {
      throw new Error('Empty access token #fiorin #initAuth');
    }

    if (!account.id) {
      throw new Error('Empty account id #fiorin #initAuth');
    }

    // do not save to _connector here! It will be initialized in the event handler
    // also do not disconnect it, it will pass it self to the event handler
    const connector = createFiorinConnector(account);
    await connector.connect({ allowDialogWhenUnauthorized: false });

    const timeout = setInterval(() => {
      console.warn(
        'Fiorin responds too long for waitForAuthStatus.\n Check every 10 sec \n #initAuth #Fiorin'
      );
    }, 10_000);

    try {
      const isLoggedIn = await connector.waitForAuthStatus();
      if (!isLoggedIn) {
        console.debug('Fiorin auth failed #initAuth #Fiorin');
        throw new Error('Fiorin auth failed, its ok though');
      }
      // do not catch it here, must catch on upper level
    } finally {
      clearTimeout(timeout);
    }
  },

  async startLogin(_, { options, account }) {
    // показываем модалку по флагу eth (чтобы в модалке иконка была другой) или по флагу true
    const dialogMode =
      options.trustWalletFlag ||
      options.metamaskFlag ||
      options.walletConnectFlag
        ? 'eth'
        : true;

    await store.dispatch('localUiSettings/setShowConnectModal', dialogMode);

    //do not save this connector, to avoid closing the current one, until new auth is complete
    //the _connector is initialized in the event handler when the auth is complete ('init')
    const connector = createFiorinConnector(account || {});
    await connector.connect({ ...options, allowDialogWhenUnauthorized: true });
  },

  async logout() {
    if (!_connector) {
      console.debug('No connector to logout #fiorin #logout');
      return;
    }
    const oldConnector = _connector;
    _connector = null;

    await oldConnector.logout();
    oldConnector.disconnect();
    //auth/onLogout will be called by the connector
  },

  //todo: [Vadim] responsibility of this module?
  setFiorinLastTxId({ commit }, id) {
    console.debug('Setting fiorinLastTxId #fiorin #dispatch', id);
    commit(types.SET_FIORIN_LAST_TX_ID, id);
  },

  async clear({ commit }) {
    console.debug('Clearing fiorin #fiorin #action #clear');
    if (_connector) {
      await _connector.disconnect();
      _connector = null;
    }

    commit('clear');
    console.debug('End clearing fiorin #fiorin #dispatch #clear');
  },

  async init({ commit }, data) {
    commit('init', data.payload);
    if (_connector) {
      await _connector.disconnect();
    }
    _connector = data.connector;
  },

  async refill({ getters }, payload) {
    assertFiorin();

    let txId = null;

    if (getters.isTon) {
      txId = await getConnector().refillTon(payload);
    } else {
      txId = await getConnector().refill(payload);
    }

    return txId;
  },

  viewDeposit() {
    assertFiorin();
    getConnector().viewDeposit();
  },
  viewWallet() {
    assertFiorin();
    getConnector().viewWallet();
  },

  async fetchBalance({ getters }) {
    assertFiorin();
    if (getters.isTon) {
      await fetchTonUsdtBalanceThrottled();
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  cachedPaths: ['fiorin.balancesLog'],
  modules: {
    maxBalanceToken: {
      namespaced: true,
      getters: {
        token: (state, getters, rootState) => {
          assertFiorin();
          return rootState.fiorin.balances.tokenWithMaxBalance;
        },

        tokenId: (state, getters) => {
          assertFiorin();
          return getters.token.tokenId;
        },

        currency: (state, getters) => {
          assertFiorin();
          return getters.token.currency;
        },

        name: (state, getters) => {
          assertFiorin();
          return getters.token.name;
        },

        amount: (state, getters) => {
          assertFiorin();
          return getters.token.amount;
        },

        amountUsd: (state, getters) => {
          assertFiorin();
          return getters.token.amountUsd;
        },

        isStable: (state, getters) => {
          assertFiorin();
          return isFiorinStable(getters.currency);
        },
      },
    },
  },
};
