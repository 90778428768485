<template>
  <transition name="fade">
    <div v-show="isVisible" style="z-index: 901">
      <Modal
        @close="onClose"
        :close-outside="false"
        :modal-class="isTelegram ? 'chart-modal telegram' : 'chart-modal'"
        :active-market="activeMarket"
        :hide-close="true"
        :in-chart="true"
        hide-back-in-mini
        full-width
        :teleport="false"
      >
        <Card
          :hide-title-and-description="true"
          id="v-step-mobile-3"
          :is-toggle="false"
          :title-class="'card__title--p15 underline'"
          :title="parseTitle($t('mobile.ordersModal.orderTitle'))"
          description-left
          :description="isTelegram ? '' : marketDescriptionName"
          emit-click-event="showMarketData"
          @showMarketData="showModal(modalsByName.marketData)"
          v-click-outside-target="hidePositionForm"
        >
          <!-- <template #title>
              <span
                id="v-step-mobile-4"
                @click="
                  $tour_goToNextStep(
                    $data.$tour_mobile,
                    () => showModal(modalsByName.marketData),
                    4
                  )
                "
              >
                {{ parseTitle($t('mobile.ordersModal.orderTitle')) }}
              </span>
            </template> -->
          <div class="search-wrap">
            <SearchInput :in-chart="true" />
          </div>

          <Chart1 mobile @onGraphClick="onGraphClick" />
          <div id="mobilePositionPanel">
            <FormPosition
              :useMobileLayout="true"
              @close="onClose"
              @payment="onPayment"
            />
          </div>

          <overlay-simple v-if="isFormShown"></overlay-simple>
        </Card>
        <ModalConnectionLost
          v-if="showConnectionLostModal"
          @close="showConnectionLostModal = false"
        />
        <ModalMarketOpenClose
          v-if="workHourModal"
          :value="workHourModal"
          @dontShowAgainMarket="dontShowAgainMarket"
          @close="workHourModal = ''"
        />
        <ModalMarketData
          v-if="modalStatus(modalsByName.marketData)"
          @close-modal="
            $tour_goToNextStep(
              $data.$tour_mobile,
              () => hideModal(modalsByName.marketData),
              5
            )
          "
        />
      </Modal>
    </div>
  </transition>
</template>

<script>
import Modal from '@/modules/modals/Modal';
import ModalMarketData from '@/modules/marketInfo/components/MarketInfoModal';
import ModalConnectionLost from '@/components/modals/ModalConnectionLost';
import ModalMarketOpenClose from '@/components/modals/ModalMarketOpenClose';
import Card from '@/components/ui/Card';
import Chart1 from '../Chart1.vue';
import FormPosition from '@/modules/formPosition/FormPosition';
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue';
import { useStore } from 'vuex';
import { useModals } from '@/modules/modals/api';
import router from '@/router';
import tourMixin from '@/mixins/tour.mixin';
import { setActiveMarketFromRoute } from '@/helpers/activeMarketRoute';
import { parseTitle } from '@/helpers/ui';
import { exchangeList } from '@/helpers/floatList';
import SearchInput from '@/components/ui/SearchInput.vue';
import { useFormVisibility } from '@/modules/formPosition/use/formComposition';
import debounce from 'lodash/debounce';
import OverlaySimple from '@/components/ui/overlaySimple.vue';
import { useRouter } from 'vue-router';
import { APP_ROUTE_NAMES } from '@/helpers/enums';

export default defineComponent({
  name: 'MobileChart',
  components: {
    OverlaySimple,
    Modal,
    ModalMarketData,
    ModalConnectionLost,
    ModalMarketOpenClose,
    Chart1,
    Card,
    FormPosition,
    SearchInput,
  },
  emits: ['close'],
  mixins: [tourMixin],
  setup(_, { emit }) {
    const store = useStore();
    const { hideForm, isFormShown } = useFormVisibility(store);

    // reset mobile flags
    localStorage.removeItem('mobileShowConnectionLost');
    localStorage.removeItem('mobileMarketOpenCloseWorkhour');

    const showConnectionLostModal = ref(
      !!localStorage.getItem('mobileShowConnectionLost')
    );
    const workHourModal = ref(
      localStorage.getItem('mobileMarketOpenCloseWorkhour')
    );

    const onClose = async () => {
      try {
        const prevRoutePath = router.options.history.state?.back;
        router.push(
          prevRoutePath === '/positions' ? prevRoutePath : '/markets'
        );
        await nextTick();
        await store.dispatch('graph/setGraphVisibility', false);
        emit('close');
      } catch (e) {
        console.error('#mobileChart onClose error', e);
      }
    };

    const onPayment = async () => {
      try {
        router.push('/positions');
        await nextTick();
        await store.dispatch('graph/setGraphVisibility', false);
      } catch (e) {
        console.error('#mobileChart onPayment error', e);
      }
    };

    const onGraphClick = debounce(() => {
      hideForm();
    }, 30);

    const dontShowAgainMarket = () => {
      store.dispatch('markets/setDontShowAgainMarket');
      workHourModal.value = '';
    };

    const activeMarket = computed(() => store.getters['markets/activeMarket']);

    const marketsByName = computed(
      () => store.getters['markets/marketsByName']
    );

    const marketDescriptionName = computed(() => {
      if (!activeMarket.value) {
        return '';
      }

      const name = activeMarket.value.name; // marketLabel(activeMarket.value.name);

      if (!name) {
        return '';
      }

      const exchange =
        activeMarket.value.source || exchangeList[activeMarket.value.name];

      if (!exchange) {
        return name;
      }

      let formattedExchange = exchange.split('');
      // formattedExchange[0] = formattedExchange[0]; // .toUpperCase();

      return `${name} (${formattedExchange.join('')})`;
    });

    const isTelegram = computed(() =>
      Boolean(store.getters['telegram/instance'])
    );

    const hidePositionForm = debounce(() => {
      hideForm();
    }, 30);

    onUnmounted(() => {
      onGraphClick.cancel();
      hidePositionForm.cancel();
    });

    const getRouteNameFromMarket = (marketName) => {
      return marketName?.replace('/', '-');
    };

    const setRoute = async () => {
      if (!activeMarket.value) {
        router.push('/markets');
        await nextTick();
        await store.dispatch('graph/setGraphVisibility', false);
        return;
      }

      const routePath =
        '/chart/' + getRouteNameFromMarket(activeMarket.value.name);

      router.push(routePath).catch((e) => {
        console.error('routePath #mobilechart router.push error', e);
      });
    };

    const isVisible = ref(false);

    watch(
      () => store.getters['graph/isGraphVisible'],
      async (value) => {
        console.debug(
          'watch #isGraphVisible #mobilechart #graph',
          'new value:',
          value,
          'old isVisible:',
          isVisible.value
        );

        isVisible.value = value;
        if (value) {
          document.querySelector('body').style.overflow = 'hidden';
          await nextTick();
          // this is needed to prevent flickering when opening the modal
          setTimeout(() => {
            setRoute();
          }, 100);
        } else {
          // console.debug('watch #graph/isGraphVisible value:', value);
          document.querySelector('body').style.overflow = 'auto';
        }
      }
    );

    const isChartRoute = (name) => name === APP_ROUTE_NAMES.chart;
    const route = useRouter();

    onMounted(() => {
      let isChart = isChartRoute(route.currentRoute.value.name);
      isVisible.value = isChart;
      // alert('mounted: ' + isChart);
      store.dispatch('graph/setGraphVisibility', isChart);
    });

    return {
      isVisible,
      isTelegram,
      parseTitle,
      store,
      activeMarket,
      dontShowAgainMarket,
      onClose,
      onPayment,
      marketsByName,
      showConnectionLostModal,
      workHourModal,
      marketDescriptionName,
      onGraphClick,
      hidePositionForm,
      isFormShown,
      setRoute,
      ...useModals(),
    };
  },
  watch: {
    marketsByName(markets) {
      if (!markets) {
        return;
      }

      // console.debug('markets #mobilechart', markets);

      setActiveMarketFromRoute();
      this.setRoute();
    },
  },
});
</script>

<style lang="scss" scoped>
/* Состояния начала и конца */
.fade-enter-from, /* Начало появления (Vue 3) */
.fade-leave-to {
  /* Конец исчезновения (Vue 2 & 3) */
  opacity: 0;
}

/* Состояния конца появления и начала исчезновения (обычно видимое состояние) */
.fade-enter-to, /* Конец появления (Vue 3) */
.fade-leave-from {
  /* Начало исчезновения (Vue 3) */
  opacity: 1; /* Явно указываем видимое состояние */
}

/* Активные фазы анимации (определяют transition) */
.fade-enter-active {
  transition: opacity 0.2s ease; /* Как появляется */
}

.fade-leave-active {
  transition: opacity 0.1s ease; /* Как исчезает */
}

.card {
  padding: 0;
}

.search-wrap {
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
  margin-bottom: 15px;
  padding: 0 20px;
}

.underline {
  text-decoration: underline;
}
</style>
