<template>
  <div
    :class="{
      openCard,
    }"
    class="account-wrapper"
    ref="accountInfo"
  >
    <div
      id="v-step-7"
      :class="{
        'account-info--hidden': activeIndex,
        'account-info--loading': walletLoading && !sleepLoading,
      }"
      class="account-info box"
    >
      <!-- <loader
        v-if="walletLoading && !sleepLoading"
        class="account-info__main-loader"
      />-->
      <template v-if="true">
        <div
          :class="{ demo: !$isLoggedIn }"
          class="account-info__title"
          @click="isToggle && toggleCard()"
        >
          <div class="userName">
            {{ $isLoggedIn ? 'Account' : 'Demo User' }}
          </div>
          <slot name="title" />
          <Icon
            v-if="isToggle"
            name="arrow_down"
            width="12"
            height="12"
            class="arrow-icon userName"
            :class="{
              openCard,
              show:
                (!$isLoggedIn
                  ? t('blocks.titles.demoAccount')
                  : userPaymail || ''
                ).length &&
                (!$isLoggedIn
                  ? t('blocks.titles.demoAccount')
                  : userPaymail || ''
                ).length < 34,
            }"
          />
        </div>
        <NewAccountInfo is-web />
        <div v-if="false" class="account-values-wrap">
          <ItemLabelValue
            v-if="$isHandCash"
            :label="balanceTitle"
            :sleep-loading="sleepLoading"
            :hint-options="{ top: '-15px', left: '115px' }"
            :hint-title="$t('account.hints.handcashBalance')"
            value=""
          >
            <!-- <Loader v-if="showLoader" class="account-info__loader" /> -->
            <span
              :class="{ show: !showLoader }"
              v-html="balance"
              class="account-value"
            />
          </ItemLabelValue>
          <ItemLabelValue
            v-else
            :label="balanceTitle"
            :sleep-loading="sleepLoading"
            :hint-options="{ top: '-20px', left: '120px' }"
            :hint-title="$t('account.hints.baseBalance')"
            value=""
          >
            <!-- <Loader v-if="showLoader" class="account-info__loader" /> -->
            <!-- <div v-if="showLoader" /> -->
            <span
              :class="{ show: !showLoader }"
              v-html="balance"
              class="account-value"
            />
          </ItemLabelValue>

          <ItemLabelValue
            :sleep-loading="sleepLoading"
            :label="$t('account.titles.openPendingPositions')"
            :hint-options="{ top: '-25px', left: '140px' }"
            :hint-title="$t('account.titles.openPendingPositionsHint')"
          >
            <!-- <Loader v-if="showLoader" class="account-info__loader" />
            <div v-else v-html="holdPending" /> -->
            <span
              :class="{ show: !showLoader }"
              v-html="openPendingBalance"
              class="account-value"
            />
          </ItemLabelValue>

          <!-- <ItemLabelValue
            :sleep-loading="sleepLoading"
            :label="$t('account.titles.pendingPositions')"
          >
            <span
              :class="{ show: !showLoader }"
              v-html="holdPending"
              class="account-value"
            />
          </ItemLabelValue>-->

          <!-- <ItemLabelValue
            :sleep-loading="sleepLoading"
            :label="$t('account.titles.openPositions')"
          >
            <span
              :class="{ show: !showLoader }"
              v-html="holdPositions"
              class="account-value"
            />
          </ItemLabelValue> -->

          <ItemLabelValue
            :label="$t('account.titles.currentRisk')"
            :hint-options="{ top: '-15px', left: '110px' }"
            :sleep-loading="sleepLoading"
            :hint-title="$t('account.hints.currentRisk')"
          >
            <!-- <Loader v-if="showLoader" class="account-info__loader" />
            <div v-else v-html="risk" /> -->
            <span
              :class="{ show: !showLoader }"
              v-html="risk"
              class="account-value"
            />
          </ItemLabelValue>

          <ItemLabelValue
            :label="$t('account.titles.total')"
            :boldLabel="true"
            :boldValue="true"
            :sleep-loading="sleepLoading"
          >
            <!-- <Loader v-if="showLoader" class="account-info__loader" />
            <div v-else v-html="totalMain" /> -->
            <span
              :class="{ show: !showLoader }"
              v-html="totalMain"
              class="account-value"
            />
          </ItemLabelValue>

          <ItemLabelValue
            :label="$t('account.titles.unrealizedPl')"
            :boldLabel="true"
            :boldValue="true"
            :is-red="+accountBalance.unrealizedPL < 0"
            :is-green="+accountBalance.unrealizedPL > 0"
            :sleep-loading="sleepLoading"
          >
            <!-- <Loader v-if="showLoader" class="account-info__loader" />
            <div v-else v-html="unrlTotalMainPL" /> -->
            <div
              :class="{ show: !showLoader }"
              v-html="unrlTotalMainPL"
              class="account-value"
            />
          </ItemLabelValue>

          <ItemLabelValue
            v-if="$isLoggedIn && sessionInfo.userProfit > 0.00009"
            :label="$t('account.titles.unsettledBalance')"
            :hint-options="{ top: '-15px', left: '90px' }"
            :sleep-loading="sleepLoading"
            :hint-title="
              $t('account.hints.unsettledBalance', {
                nextSettlement: settlementDynamicValue,
              })
            "
            @mouseover="updateInfo"
          >
            <!-- <Loader v-if="showLoader" class="account-info__loader" />
            <div
              v-else
              v-html="
                !sessionInfo.userProfit
                  ? '$0'
                  : usdBsvFormatAmount(sessionInfo.userProfit || 0)
              "
            /> -->
            <span
              :class="{ show: !showLoader }"
              v-html="
                !sessionInfo.userProfit
                  ? '$0'
                  : usdBsvFormatAmount(sessionInfo.userProfit || 0)
              "
              class="account-value"
            />
          </ItemLabelValue>
          <ItemLabelValue
            v-if="false && $isLoggedIn && incomingBalance !== '$0'"
            :label="$t('account.titles.incomingBalance')"
            :hint-options="{ top: '-55px', left: '80px' }"
            :hint-title="$t('account.hints.incomingBalance')"
            :sleep-loading="sleepLoading"
          >
            <!-- <Loader v-if="showLoader" class="account-info__loader" />
            <div v-else v-html="incomingBalance" /> -->
            <span
              :class="{ show: !showLoader }"
              v-html="incomingBalance"
              class="account-value"
            />
          </ItemLabelValue>
          <!-- v-if="bountyTasks && bountyTasks.start" -->
          <ItemLabelValue
            v-if="!showLoader && $isFiorin && bountyProgramAvailable"
            :sleep-loading="sleepLoading"
            :label="$t('account.titles.bountyBalance')"
          >
            <!-- <Loader v-if="showLoader" class="account-info__loader" /> -->
            <!-- <div v-else v-html="bountyBalance" /> -->
            <span
              :class="{ show: !showLoader }"
              v-html="bountyBalanceUsd"
              class="account-value"
            />
          </ItemLabelValue>
          <div
            v-if="!(!showLoader && $isFiorin) && isMobile"
            class="no-bounty"
          />
          <ItemLabelValue
            v-if="!hideGrandTotal"
            :label="$t('account.titles.grandTotal')"
            :boldLabel="true"
            :boldValue="true"
            :sleep-loading="sleepLoading"
          >
            <!-- <Loader
              v-if="showLoader || grandTotalLoader"
              class="account-info__loader"
            />
            <div v-else v-html="totalMainPL" /> -->
            <span
              :class="{ show: !showLoader }"
              v-html="totalMainPL"
              class="account-value"
            />
          </ItemLabelValue>
        </div>
        <div v-if="false || hideGrandTotal" class="grand-total">
          <div class="grand-total__title">
            {{ $t('mobile.account.grandTotal') }}
          </div>
          <Loader
            v-if="showLoader || grandTotalLoader || grandLoader"
            class="grand-loader"
          />
          <template v-else>
            <div
              v-if="!$isFiorinMode"
              :class="{ inSleep: sleepLoading }"
              class="grand-total__value"
            >
              <!--eslint-disable-next-line-->
              {{ balanceTotalWithPl }}
              <div class="text-secondary bsv" @click="copy">BSV</div>
            </div>
            <div
              :class="{ fiorinMode: this.$isFiorinMode, inSleep: sleepLoading }"
              class="grand-total__usd text-main"
              @click="copy"
            >
              {{
                +accountBalance.totalWithPL
                  ? `$${usdTotalBalance}`
                  : usdTotalBalance
              }}
            </div>
          </template>
        </div>
        <div v-if="false" class="account-btns-wrap">
          <!-- <Button
            v-if="!activeConnect.provider && !userPaymail"
            :text="$t('blocks.buttons.login')"
            is-login-btn
            class="contest-btn shortcut-btn"
            @click="store.dispatch('burger/login')"
          /> -->
          <Button
            v-if="!$isLoggedIn"
            text="READ FAQs"
            is-login-btn
            class="contest-btn shortcut-btn"
            @click="clickFaq"
          />

          <template v-if="$isLoggedIn">
            <Button
              v-if="!favouriteItems.length"
              :text="
                settings.providedLiquidity
                  ? $t('popups.liquidityFundraiser.title2')
                  : $t('popups.liquidityFundraiser.title')
              "
              class="contest-btn btn shortcut-btn"
              @click="openLiquidity"
            />
            <template v-if="isMobile || !favouriteItems.length">
              <Button
                v-for="item in favouriteItems"
                :key="item.title + item.action"
                :text="buttonTitle(item)"
                class="contest-btn shortcut-btn"
                @mousedown="handleMenuItemClick(item)"
              />
            </template>
            <Button
              v-if="!isMobile && favouriteItems.length > 2"
              :text="$t('blocks.titles.shortcuts')"
              class="contest-btn btn shortcut-btn"
              @mousedown="onChangeTabIndex(1)"
            />
            <template v-if="!isMobile && favouriteItems.length <= 2">
              <Button
                v-for="item in favouriteItems"
                :key="item.action"
                class="contest-btn btn shortcut-btn"
                :text="buttonTitle(item)"
                @mousedown="handleMenuItemClick(item)"
              />
            </template>
          </template>
        </div>
        <!-- {{ showLoader }}
        <br />
        {{ authLoader }} {{ actualShowLoader }} {{ !sleepLoading }} -->
      </template>
    </div>
    <div
      v-if="false"
      :class="{
        'account-info--hidden': !activeIndex,
        'account-info--loading': walletLoading && !sleepLoading,
      }"
      class="account-info shortcuts box"
    >
      <div class="account-info__title" @click="isToggle && toggleCard()">
        {{ $t('blocks.titles.shortcuts') }}
        <Icon
          v-if="isToggle"
          :class="{ openCard }"
          name="arrow_down"
          width="12"
          height="12"
          class="arrow-icon"
        />
      </div>
      <div class="account-btns-wrap">
        <Button
          v-for="item in favouriteItems"
          :key="item.title + item.action"
          :text="buttonTitle(item)"
          class="shortcut-btn contest-btn"
          @mousedown="handleMenuItemClick(item)"
        />
        <Button
          :text="$t('blocks.buttons.hide')"
          class="contest-btn disabled shortcut-btn"
          @mousedown="onChangeTabIndex(0)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ItemLabelValue from '@/components/ui/ItemLabelValue.vue';
import Loader from '@/components/ui/Loader.vue';
import Button from '@/components/ui/Button.vue';
import Icon from '@/components/ui/Icon.vue';

import { mapGetters, useStore } from 'vuex';

import '@/assets/icons/arrow_down';

import useToggleCard from '@/compositions/useToggleCard';
import useChangeFilter from '@/compositions/useChangeFilter';
import { useModals } from '@/modules/modals/api';
import { useI18n } from 'vue-i18n';
import useUserActions from '@/compositions/useUserActions';
import bsvPrice from '@/compositions/bsvPrice';

import { toCurrencyFormat, formatMinutes } from '@/helpers/utils';

import { computed, ref, onMounted, onBeforeUnmount, watch } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { DEFAULT_MARKET_PAIR } from '@/modules/markets/helpers';
import router from '@/router';

import { scope } from '@/breakpoints';
import { CONSTANT_ACTIONS } from '@/config/constantActions';
import { usdBsvFormatAmount } from '@/helpers/utils';
import DateTime from 'luxon/src/datetime.js';
import { copyText } from 'vue3-clipboard';
import { sendUiSettingsAndLoadAllSettings } from '@/modules/settings/api';
import BigNumber from 'bignumber.js';
import notify from '@/plugins/notify';
import { parseTitle } from '@/helpers/ui';
import NewAccountInfo from '@/modules/accountInfo/NewAccountInfo.vue';

import authMixin from '@/mixins/auth.mixin';
import store from '@/store';

export default {
  name: 'AccountInfo',
  mixins: [authMixin],
  components: { Loader, ItemLabelValue, Icon, Button, NewAccountInfo },
  props: {
    hideGrandTotal: {
      type: Boolean,
      default: false,
    },
    isToggle: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    actualShowLoader: true,
    grandLoader: true,
  }),
  created() {
    if (
      (this.accountBalance && this.accountBalance.available > 0) ||
      this.$isLoggedIn
    ) {
      if (this.firstBalanceLoading) {
        setTimeout(() => {
          this.actualShowLoader = false;
          this.grandLoader = false;
          this.$store.dispatch('settings/setFirstBalanceLoading', false);
        }, 4500);
      } else {
        this.actualShowLoader = false;
        this.grandLoader = false;
      }

      // setTimeout(() => {
      //   this.grandLoader = false;
      // }, 3500);
    }
  },
  setup(props, { emit }) {
    const store = useStore();
    const { t } = useI18n();
    const userActions = useUserActions(props, { emit });
    const { openCard, toggleCard } = useToggleCard();
    // 0 for account, 1 for shortcuts
    const { activeIndex, onChangeTabIndex } = useChangeFilter();
    const { showModal, hideModal, modalStatus, modalsByName } = useModals();
    const exportCount = ref(0);
    const settlementDynamicValue = ref('');
    const updateBlocker = ref(false);

    const bountyProgramAvailable = computed(() => {
      return store.getters['settings/settings']?.bountyProgramAvailable;
    });

    const sessionLogs = computed(() => {
      return store.getters['session/sessionLogs'];
    });

    const grandTotalLoader = computed(
      () => store.getters['session/grandTotalLoader']
    );

    const copy = async () => {
      exportCount.value++;

      if (exportCount.value >= 5) {
        copyText(JSON.stringify(sessionLogs.value));
        // copyText(localStorage.getItem('fiorinPaymail'));
        exportCount.value = 0;

        await sendUiSettingsAndLoadAllSettings({
          blockedFromIp: null,
          emailVerificationLater: null,
        });
      }
    };

    const settings = computed(() => {
      return store.getters['settings/settings'];
    });

    const walletLoading = computed(() => {
      return false;
    });

    const incomingBalance = computed(() => {
      if (!store.getters['positions/getIncomingBalance']) {
        return '$0';
      }
      return usdBsvFormatAmount(store.getters['positions/getIncomingBalance']);
    });

    const sessionInfo = computed(() => store.getters['session/info']);

    const accountInfo = ref(null);
    onClickOutside(accountInfo, () => onChangeTabIndex(0));

    const isMobile = computed(
      () => scope.isSmall || scope.isMedium || scope.noMatch
    );

    const setScrollStatus = (action) => {
      const classNode = 'mobAccount';
      // document.querySelector('.layout-mobile').classList[action](classNode);
      document.querySelector('body').classList[action](classNode);
      document.querySelector('html').classList[action](classNode);
    };

    const filterList = (list, topUp = 'topUpWallet') => {
      const item = list.find((item) => item.action === topUp);

      if (!item) {
        return list;
      }

      return [item, ...list.filter((item) => item.action !== topUp)];
    };

    const favouriteItems = computed(() => {
      if (isMobile.value) {
        return filterList(
          store.getters['burger/favouriteItems']
            .filter((item) => {
              return !item.desktop;
            })
            .filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.action === value.action)
            )
        );
      } else {
        return filterList(
          store.getters['burger/favouriteItems']
            .filter((item) => {
              return !item.mobile;
            })
            .filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.action === value.action)
            )
        );
      }
    });

    const bountyTasks = computed(() => store.getters['bounty/bountyTasks']);

    const setScroll = () => {
      if (isMobile.value) {
        if (window.document.body.clientWidth >= 390) {
          if (favouriteItems.value.length > 2) {
            setScrollStatus('remove');
          } else {
            setScrollStatus('add');
          }
        }

        if (window.document.body.clientWidth < 390) {
          if (favouriteItems.value.length > 1) {
            setScrollStatus('remove');
          } else {
            setScrollStatus('add');
          }
        }
      }
    };

    onMounted(() => {
      setScroll();
      settlementDynamicValue.value = formatMinutes(
        DateTime.fromISO(sessionInfo.value.sessionEnd).diffNow('minutes')
          .minutes
      );
    });

    onBeforeUnmount(() => {
      if (isMobile.value) {
        setScrollStatus('remove');
      }
    });

    watch(
      () => favouriteItems.value,
      () => {
        setScroll();
      }
    );

    const buttonTitle = (item) => {
      if (item.action === CONSTANT_ACTIONS.PROVIDE_LIQUIDITY) {
        return settings.value?.providedLiquidity
          ? t('popups.liquidityFundraiser.title2')
          : t('popups.liquidityFundraiser.title');
      }

      return t(item.title);
    };

    const handleMenuItemClick = (item) => {
      if (item.action === CONSTANT_ACTIONS.PROVIDE_LIQUIDITY) {
        userActions.openLiquidity();
      } else if (item.action === CONSTANT_ACTIONS.EXPORT) {
        userActions.exportHistory();
      } else if (item.action === CONSTANT_ACTIONS.FEATURES) {
        userActions.showCrowdfundingModal();
      } else if (item.action === CONSTANT_ACTIONS.BUY_CRYPTO) {
        userActions.openCryptoTab();
      } else if (item.action === CONSTANT_ACTIONS.STATISTICS) {
        userActions.openStats();
      } else {
        const value = item.title.split('.')[item.title.split('.').length - 1];
        store.dispatch(`burger/${item.action}`, { value });
      }
    };

    const updateInfo = () => {
      if (updateBlocker.value) {
        return;
      }

      updateBlocker.value = true;
      store.dispatch('session/updateInfo');
      settlementDynamicValue.value = formatMinutes(
        DateTime.fromISO(sessionInfo.value.sessionEnd).diffNow('minutes')
          .minutes
      );
      setTimeout(() => {
        updateBlocker.value = false;
      }, 5000);
    };

    const clickFaq = () => {
      window.open('https://faq.dxs.app/', '_blank');
    };

    return {
      clickFaq,
      copy,
      settings,
      modalStatus,
      showModal,
      hideModal,
      modalsByName,
      t,
      store,
      router,
      openCard,
      toggleCard,
      activeIndex,
      accountInfo,
      onChangeTabIndex,
      favouriteItems,
      handleMenuItemClick,
      walletLoading,
      settlementDynamicValue,
      updateInfo,

      DEFAULT_MARKET_PAIR,

      scope,
      isMobile,
      buttonTitle,
      incomingBalance,
      sessionInfo,
      grandTotalLoader,
      usdBsvFormatAmount,
      bountyTasks,
      bountyProgramAvailable,
      openLiquidity: userActions.openLiquidity,
      parseTitle,
    };
  },
  watch: {
    sleepLoading() {
      if (this.sleepLoading) {
        notify({ text: this.$t('account.price.updating'), type: 'info' });
      }
    },
  },
  computed: {
    ...mapGetters({
      accountBalance: 'user/getAccountBalance',
      markets: 'markets/markets',
      sessionInfo: 'session/info',
      firstBalanceLoading: 'settings/firstBalanceLoading',
      sleepLoading: 'positions/sleepLoading',
    }),
    showLoader() {
      return this.$isLoggedIn
        ? this.actualShowLoader && !this.sleepLoading
        : false;
    },
    userPaymail() {
      return store.getters['wallet/userName'];
    },
    openPendingBalance() {
      if (this.accountBalance.hold_on_total === '') {
        return '';
      } else if (!this.accountBalance.hold_on_total) {
        return '$0';
      }
      return usdBsvFormatAmount(this.accountBalance.hold_on_total);
    },
    holdPending() {
      if (this.accountBalance.hold_on_pending === '') {
        return '';
      } else if (!this.accountBalance.hold_on_pending) {
        return '$0';
      }
      return usdBsvFormatAmount(this.accountBalance.hold_on_pending);
    },
    holdPositions() {
      if (this.accountBalance.hold_on_positions === '') {
        return '';
      } else if (!this.accountBalance.hold_on_positions) {
        return '$0';
      }
      return usdBsvFormatAmount(this.accountBalance.hold_on_positions);
    },
    risk() {
      if (this.accountBalance.risk === '') {
        return '';
      } else if (!+this.accountBalance.risk) {
        return '$0';
      }
      return usdBsvFormatAmount(this.accountBalance.risk);
    },
    totalMain() {
      if (this.accountBalance.total === '') {
        return '';
      } else if (!+this.accountBalance.total) {
        return '$0';
      }
      let result = usdBsvFormatAmount(this.accountBalance.total) || 0;
      // console.debug('#accountInfo #totalMain', result);
      return result;
    },
    totalMainPL() {
      if (!+this.actualGrandTotal) {
        return '$0';
      }

      let result = usdBsvFormatAmount(
        this.actualGrandTotal,
        this.balancePostfix
      );
      // console.debug('#accountInfo #totalMainPL', result);
      return result;
    },
    actualGrandTotal() {
      return BigNumber(
        +this.accountBalance.totalWithPL + (+this.sessionInfo.userProfit || 0)
      ).toFixed();
    },
    unrlTotalMainPL() {
      if (this.accountBalance.unrealizedPL === '') {
        return '';
      } else if (!+this.accountBalance.unrealizedPL) {
        return '$0';
      }

      let result = usdBsvFormatAmount(
        this.accountBalance.unrealizedPL,
        '',
        this.accountBalance.unrealizedPL > 0
      );
      // console.debug('#accountInfo #unrlTotalMainPL', result);

      return result;
    },
    balance() {
      const pendingUsd = store.getters['wallet/pendingUsd'];

      if (!this.accountBalance.available) {
        if (pendingUsd) {
          return `$0 (+$${pendingUsd})`;
        }
        return '$0';
      }

      const formattedBalance = usdBsvFormatAmount(
        this.accountBalance.available,
        this.balancePostfix
      );

      const result = pendingUsd
        ? `${formattedBalance} (+$${pendingUsd})`
        : formattedBalance;

      // console.debug('#accountInfo #balance', result);

      return result;
    },

    bountyBalanceUsd() {
      const bountyBalanceUsd = store.getters['bounty/balanceUsd'];
      if (!bountyBalanceUsd) {
        return '$0';
      }

      return usdBsvFormatAmount(bountyBalanceUsd, this.balancePostfix);
    },

    usdTotalBalance() {
      if (!+this.accountBalance.totalWithPL) {
        return '$0';
      }

      const exRate = this.$isFiorinMode ? 1 : bsvPrice();
      const totalBalance =
        exRate *
        (+this.accountBalance.totalWithPL +
          (+this.sessionInfo.userProfit || 0));
      return toCurrencyFormat(BigNumber(totalBalance).toFixed());
    },

    balanceTotalWithPl() {
      let result = toCurrencyFormat(this.actualGrandTotal, 'nospace');
      // console.debug('#accountInfo #balanceTotalWithPl', result);
      return result;
    },

    // todo: [Vadim] remove
    balancePostfix() {
      return '';
    },

    balanceTitle() {
      return this.t('account.titles.walletBalance');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.account-wrapper {
  position: relative;
  height: 57px;
  overflow: hidden;

  .account-values-wrap {
    min-height: 205px; // 255px;
  }

  .no-bounty {
    margin-bottom: 1rem;
    height: 19px;
    width: 1px;
  }

  @media screen and (max-width: 480px) {
    height: 100% !important;
    min-height: 100vh !important;
  }

  .grand-loader {
    position: relative;
    margin-top: 15.5px;
    margin-bottom: 19px;
  }

  &.openCard {
    height: auto;
  }

  &:not(.openCard) {
    .shortcuts {
      .account-btns-wrap {
        display: none;
      }
    }
  }
}

.account-info {
  padding: 18px 20px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  min-height: auto;
  height: auto;
  position: relative;

  @media screen and (max-width: 480px) {
    padding: 18px 20px;
  }

  .account-value {
    transition: all 0.3s ease-in-out;
    opacity: 0;

    &.show {
      opacity: 1;
    }
  }

  @media screen and (max-width: 800px) {
    height: calc(100% - 50px);
    min-height: calc(100vh - 50px);
    padding-bottom: 50px;
    margin-bottom: 50px;
    position: relative;
    overflow-y: auto;
    // margin-bottom: 100px;
  }

  .contest-btn:not(.shortcut-btn):last-child {
    margin-bottom: 0;
  }

  .contest-btn {
    cursor: pointer;

    &.shortcut-btn {
      min-height: 51px;
      width: 100%;
      padding-top: 3px;

      &:last-child {
        @media screen and (max-width: 480px) {
          margin-bottom: 50px;
        }
      }
    }
  }

  &.shortcuts {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    .account-btns-wrap {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  &__loader {
    position: relative;
    right: 0;
    bottom: 2px;
  }

  &__main-loader {
    top: 50%;
    left: 50%;
    margin-left: -18px;
  }

  &--hidden {
    visibility: hidden;
    opacity: 0;
  }

  &--loading {
    min-height: 200px;
  }

  &__title {
    @include card-title;
    // text-transform: uppercase;

    &.demo {
      text-transform: capitalize;
    }

    font-size: 22px;

    @media screen and (max-width: 1024px) {
      margin-bottom: 20px;
      justify-content: space-between;
    }
  }
}

.grand-total {
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 68px;

  &__title {
    font-size: 0.9375rem;
    text-align: left;
    font-family: Gotham_Pro_Regular;
    color: $color-gray2;
  }

  &__value {
    font-size: 36px;
    font-family: Gotham_Pro_Bold;
    margin-top: 5px;
    color: $color-gray2;

    &.inSleep {
      opacity: 0.2;
    }

    @media screen and (max-width: 480px) {
      margin-top: 3.5px;
    }

    .bsv {
      display: inline-block;
      font-size: 0.9375rem;
      margin-left: 3px;
    }
  }

  &__usd {
    font-size: 0.9375rem;
    line-height: 1.05;
    font-family: Cantarell_Bold;

    &.inSleep {
      opacity: 0.2;
    }

    &.fiorinMode {
      font-size: 25px;
      margin-top: 10px;
    }
  }
}

.hide-trade-btn {
  display: none;
}

@media screen and (max-width: 1024px) {
  .hide-trade-btn {
    display: flex;
  }

  .account-btns-wrap {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.grandTotalLoader {
      padding-top: 2rem;
    }

    .contest-btn.btn {
      width: 256px;
      min-height: 51px;
    }
  }
}

@media screen and (max-width: 400px) {
  .account-btns-wrap {
    padding-top: 0;
  }
}
</style>
