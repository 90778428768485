<template>
  <div class="token-list">
    <div
      class="token-list__item"
      :class="{
        selected: item.name === selectedToken,
        last: ndx === tokensList.length - 1,
      }"
      :key="ndx"
      @click="handleClick(item)"
      v-for="(item, ndx) in tokensList"
    >
      {{ item.name }}
      <icon
        v-if="item.name === selectedToken"
        name="check"
        :width="12"
        :height="12"
        :class="{ active: item.name === selectedToken }"
        class="select-icon"
      />
    </div>
  </div>
</template>
<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import '@/assets/icons/check';
import authMixin from '@/mixins/auth.mixin';
import useAuth from '@/compositions/useAuth';

export default {
  mixins: [authMixin],
  emits: ['click'],
  props: {
    selectedToken: {
      type: String,
      required: true,
    },
  },
  setup(_, { emit }) {
    const auth = useAuth();
    const store = useStore();

    const tokensList = computed(() => {
      if (!auth.isFiorin.value) {
        return [];
      }

      return store.getters['tokenSelector/fitTokens'];
    });

    const handleClick = (selectedToken) => {
      emit('click', selectedToken);
    };

    return {
      tokensList,
      handleClick,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/panels.scss';
.token-list {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  overflow: hidden;

  .select-icon {
    position: absolute;
    right: 15px;
    top: 24px;
    opacity: 0;

    g {
      fill: $colorActiveBlue;
    }

    &.active {
      opacity: 1;
    }

    g {
      fill: $colorActiveBlue;
    }
  }

  &__item {
    position: relative;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
    color: $colorTextDark;
    background: $backgroundPanelColorDark;
    padding: 0 20px;
    font-family: Gotham_Pro_Medium;
    margin-bottom: 1px;
    cursor: pointer;

    &:hover {
      background: $backgroundHoverColorDark;
      color: $colorActiveBlue;

      .select-icon {
        opacity: 1;
        display: initial !important;
      }
    }

    &.last {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &.selected {
      background: $backgroundHoverColorDark;
      color: $colorActiveBlue;

      svg.select-icon {
        g {
          fill: $colorActiveBlue;
        }
      }
    }
  }

  body.light {
    &__item {
      background: $backgroundPanelColorLight;
      color: $colorTextLight;

      &:hover {
        background: $backgroundHoverColorLight;
        color: $colorActiveBlue;
      }

      &.selected {
        background: $backgroundHoverColorLight;
      }
    }
  }
}
</style>
