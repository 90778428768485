import store from '@/store';
import notify from '@/plugins/notify';
import { WalletNames } from '@/wallets/common';

function getFiorinEventHandlers() {
  return {
    READY: async () => {},

    AUTHORIZED: async () => {},

    AUTHORIZED_COMPLETED: async (id, { payload, connector }) => {
      await store.dispatch('fiorin/clear');

      if (!connector) {
        throw new Error('No connector in AUTHORIZED_COMPLETED event');
      }

      if (!payload) {
        throw new Error('No payload in AUTHORIZED_COMPLETED event');
      }

      const user = payload.user;
      if (!user.token) {
        throw new Error('No token in AUTHORIZED_COMPLETED event');
      }

      if (!payload.balances) {
        throw new Error('No balances in AUTHORIZED_COMPLETED event');
      }

      const userName = user.username;
      const paymail = user.bsvAccountAddress;
      const accessToken = user.token;
      const appId = user.packet;
      const targetWeb3Provider = user.provider;
      const email = user.email;

      //todo: [vadim] when we have targetProvider,what will be the paymail?

      await store.dispatch('fiorin/init', {
        payload: {
          paymail,
          userName,
          email,
          targetWeb3Provider,
        },
        connector,
      });

      await store.commit('fiorin/setBalances', payload.balances);

      await store.dispatch('auth/onAuthenticated', {
        id,
        userName,
        accessToken,
        appId,
        provider: WalletNames.fiorin,
        targetWeb3Provider,
      });

      await store.dispatch('wallet/fetchBalance');
      await store.dispatch('localUiSettings/setShowConnectModal', false);
    },

    ERROR: (id, payload) => {
      notify({
        text: payload.message || payload.error,
        type: 'error',
      });
    },

    REFILL_COMPLETED: async (id, payload) => {
      console.debug(
        'REFILL_COMPLETED, calling fiorin/setFiorinLastTxId',
        payload
      );
      await store.dispatch('fiorin/setFiorinLastTxId', payload.txId);
    },

    WITHDRAWAL: () => {
      notify({
        text: 'Sending funds...',
        type: 'info',
      });
    },

    DEPOSIT: () => {
      notify({
        text: 'Receiving funds...',
        type: 'info',
      });
    },

    BALANCES: async (id, payload) => {
      await store.commit('fiorin/setBalances', payload);
    },

    'BALANCE:TON': async (id, usdtTokenBalance) => {
      await store.commit('fiorin/setBalancesTonUsdt', usdtTokenBalance);
    },

    PENDING_BALANCE: async (id, payload) => {
      await store.commit('fiorin/setBalances', payload);
    },

    BOUNTY_BALANCE: async (id, payload) => {
      await store.commit('fiorin/setBalances', payload);
      // [Vadim]: disabled since not needed
      // await store.dispatch('bounty/checkBountyUpdated');
    },

    LOGOUT_COMPLETED: async () => {
      await store.dispatch('auth/onLogout');
      await store.dispatch('localUiSettings/setShowConnectModal', false);
    },

    OPEN_ME: async () => {
      store.commit('localUiSettings/SET_SHOW_OVERLAY', true);
    },

    CLOSED: async () => {
      store.commit('localUiSettings/SET_SHOW_OVERLAY', false);
      await store.dispatch('localUiSettings/setShowConnectModal', false);
    },

    FIORIN_DELAYED: async () => {
      console.debug('Fiorin is delayed...');
      notify({
        text: 'Ask support: 03211',
        type: 'info',
        duration: 3_000,
      });
    },
  };
}

export default getFiorinEventHandlers;
