<template>
  <div
    class="mobile-footer box"
    :class="{
      scrollingListFlag,
      overLay: isBurgerOpened,
      standalone: wd.navigator.standalone,
      IOSTg: isIOSTg,
      notLogin: !$isLoggedIn,
      isFiorinNullableBalance,
    }"
  >
    <div
      @click="goToMarkets"
      class="mobile-footer__item"
      :class="{ active: isMarketsTab }"
    >
      <icon v-if="isMarketsTab" name="markets_on" width="26" height="26" />
      <icon v-else name="markets_off" width="26" height="26" />
    </div>
    <div
      id="v-step-mobile-6"
      class="mobile-footer__item"
      :class="{ active: isPositionsTab }"
      @click="
        $tour_goToNextStep(
          $data.$tour_mobile,
          () => router.push('/positions'),
          8
        )
      "
    >
      <icon v-if="isPositionsTab" name="positions_on" width="26" height="26" />
      <icon v-else name="positions_off" width="26" height="26" />
    </div>
    <div
      id="v-step-mobile-11"
      class="mobile-footer__item"
      :class="{ active: isAccountTab }"
      @click="
        $tour_goToNextStep(
          $data.$tour_mobile,
          () => router.push('/account'),
          11
        )
      "
    >
      <icon v-if="isAccountTab" name="account_on" width="26" height="26" />
      <icon v-else name="account_off" width="26" height="26" />
    </div>
    <app-overlay v-if="isBurgerOpened" />
  </div>
</template>
<script>
import tourMixin from '@/mixins/tour.mixin';
import '@/assets/icons/markets_on';
import '@/assets/icons/positions_on';
import '@/assets/icons/account_on';
import '@/assets/icons/markets_off';
import '@/assets/icons/positions_off';
import '@/assets/icons/account_off';

import { defineComponent, computed, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { TABS_ROUTE_NAMES } from '@/helpers/enums';
import { useStore } from 'vuex';

import AppOverlay from '@/components/ui/Overlay';
import { useFiorinBalanceNull } from '@/compositions/useFiorinBalanceNull';
import { isIOS } from '@/helpers/detects';

import authMixin from '@/mixins/auth.mixin';

export default defineComponent({
  mixins: [tourMixin, authMixin],
  components: { AppOverlay },
  setup() {
    const { isFiorinNullableBalance } = useFiorinBalanceNull();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const isScrollingFlag = ref(false);

    const isIOSTg = computed(
      () => Boolean(store.getters['telegram/instance']) && isIOS()
    );

    const isMarketsTab = computed(
      () => router.currentRoute.value.name === TABS_ROUTE_NAMES.markets
    );
    const isPositionsTab = computed(
      () => router.currentRoute.value.name === TABS_ROUTE_NAMES.positions
    );
    const isAccountTab = computed(() =>
      [TABS_ROUTE_NAMES.default, TABS_ROUTE_NAMES.account].includes(
        router.currentRoute.value.name
      )
    );

    const isBurgerOpened = computed(() => {
      return store.getters['burger/isBurgerOpened'];
    });

    const scrollingListFlag = computed(() => {
      return store.getters['localUiSettings/scrollingListFlag'];
    });

    const setScrollStatus = (action) => {
      const classNode = 'scrolling';
      document.querySelector('.layout-mobile').classList[action](classNode);
      document.querySelector('body').classList[action](classNode);
      document.querySelector('html').classList[action](classNode);
    };

    const goToMarkets = () => {
      if (route.name !== 'Markets') {
        router.push('/markets');
        return;
      }

      if (!document.documentElement.scrollTop) {
        return;
      }

      if (isScrollingFlag.value) {
        router.push('/markets');
        return;
      }

      isScrollingFlag.value = true;
      setScrollStatus('add');
      setTimeout(() => {
        setScrollStatus('remove');
      }, 100);
      router.push('/markets');

      setTimeout(() => {
        isScrollingFlag.value = false;
      }, 2000);
    };

    return {
      scrollingListFlag,
      goToMarkets,
      router,
      isMarketsTab,
      isPositionsTab,
      isAccountTab,
      isBurgerOpened,
      wd: window,
      isIOSTg,
      isFiorinNullableBalance,
    };
  },
});
</script>
<style lang="scss">
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.body--light {
  .mobile-footer {
    &__item {
      svg g {
        fill: #888 !important;
      }
    }
  }

  .router-link-active {
    svg g {
      fill: #000 !important;
    }
  }
}

.mobile-footer {
  // height: 100%;
  width: 100%;
  height: 50px;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-width: 1px;
  border-bottom-color: transparent;

  &.scrollingListFlag {
    opacity: 0.4;
  }

  &.notLogin {
    bottom: 58px; // 73px; // после изменения кнопки валлет коннект

    &.standalone {
      bottom: 94px; // 83px;
    }

    &.IOSTg {
      bottom: 80px;
    }
  }

  &.standalone {
    // height: 78px;
    // padding-bottom: 25px;
    bottom: 32px;
  }

  &.isFiorinNullableBalance {
    bottom: 60px;

    &.standalone {
      bottom: 92px;
    }
  }

  &__item {
    display: flex;
    opacity: 0.4;
    width: 100%;
    justify-content: center;

    &.active {
      opacity: 1;
    }
  }
}
</style>
