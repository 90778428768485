<template>
  <div
    :class="{
      toggleLeverageEntry,
      isWeb: !$isMobile,
      isTelegram,
    }"
    class="form-position-expanded"
    :key="activeMarket.name"
  >
    <Selector
      description="SELECT LEVERAGE"
      description-input="EXPAND"
      description-secodary="SELECT % OF MAX AMOUNT"
      hide-all-description
      :update-value="amountUsd"
      :optional-btn="optionalLeverageBtnTitle"
      :optional-btn-secondary="optionalAmountBtnTitle"
      :items="leverageItems"
      :allow-selecting-non-existing-item="true"
      :hide-items="!!toggleLeverageEntry"
      :secondary-items="percentAmountItems"
      :initial-slider-value="leverage"
      :initial-percent-amount="amountPercent"
      :input-title-label="
        !enableToOpenPosition
          ? isFiorinNullableBalance
            ? 'Wallet depleted'
            : $t('popups.marketInactive.limitReached')
          : 'AMOUNT, USD'
      "
      :max-value="+maxPositionAmountUsd"
      :disabled="!activeMarket || !activeMarket.name || !enableToOpenPosition"
      lefted
      fixed-arrow-width
      show-input-first
      not-reset-input-after-select
      @selectItem="set.setLeverage"
      @selectSecondary="set.setAmountPercent"
      @changeInput="set.setAmountUsd"
      eventGroupName="formPosition"
    />
    <div v-if="toggleLeverageEntry" class="new-field">
      <SimpleInput
        :value="leverage"
        type="text"
        directive="number"
        :min-value="1"
        new-version
        :max-value="+activeMarket.max_leverage"
        :title="$t('order.new.titles.leverage')"
        @onChange="set.setLeverage"
        eventGroupName="formPosition"
      />
    </div>
    <div class="new-field">
      <!-- :tip="entryPriceError ? '' : entryPriceTip" -->
      <SimpleInput
        :value="entryPrice"
        :note-clickable="true"
        type="text"
        :error="entryPriceError || ''"
        directive="number"
        :title="$t('order.new.titles.entryPrice')"
        with-arrows
        new-version
        :tip="entryPriceTip"
        @note-clicked="onEntryPriceNoteClickHandler()"
        @onChange="set.setEntryPrice"
        @onArrowLeft="onArrowLeft"
        @onArrowRight="onArrowRight"
        eventGroupName="formPosition"
      />
    </div>
    <div class="new-field row">
      <div class="field">
        <SimpleInput
          :value="takeProfit"
          type="text"
          :tip="takeProfitTip"
          :error="tpslErrorMessage[0]"
          directive="number"
          :title="
            $t(
              riskInPercents
                ? 'order.new.titles.takeProfitPercent'
                : 'order.new.titles.takeProfitPrice'
            )
          "
          new-version
          @onChange="set.setTakeProfit"
          eventGroupName="formPosition"
        />
      </div>
      <div class="field">
        <SimpleInput
          :value="stopLoss"
          type="inputType"
          :tip="stopLossTip"
          :error="tpslErrorMessage[1]"
          :title="
            $t(
              riskInPercents
                ? 'order.new.titles.stopLossPercent'
                : 'order.new.titles.stopLossPrice'
            )
          "
          directive="number"
          new-version
          @onChange="set.setStopLoss"
          eventGroupName="formPosition"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from 'vuex';
import { computed, watch } from 'vue';
import {
  toCurrencyFormat,
  toUSDMarginFormat,
  getPrecision,
  getPrecisionByPrice,
  floorNumber,
} from '@/helpers/utils';
import { floatList } from '@/helpers/floatList';
import SimpleInput from '@/components/ui/SimpleInput';
import { useI18n } from 'vue-i18n';
import Selector from '@/components/ui/Selector';
import { useModals } from '@/modules/modals/api';
import authMixin from '@/mixins/auth.mixin';
import screenMixin from '@/mixins/screen.mixin';
import { usePositionForm } from '@/modules/formPosition/use/formComposition';
import { PositionSides } from '@/config/constants';

export default {
  name: 'FormPositionExpandedForm',

  mixins: [authMixin, screenMixin],
  components: { SimpleInput, Selector },
  props: {
    orderType: { required: true },
    isBounty: { required: true },
  },
  setup(props) {
    const onFocus = () => {
      // if (window.document.body.clientWidth <= 480) {
      //   // window.scrollTo(0, document.body.scrollHeight);
      //   window.scrollTo({
      //     top: document.body.scrollHeight,
      //     behavior: 'smooth',
      //   });
      // }
    };

    const store = useStore();
    const { showModal, modalsByName } = useModals();

    const toggleLeverageEntry = computed(() =>
      store.getters['settings/uiSetting']('toggleLeverageEntry')
    );

    const {
      form: {
        entryPrice,
        amountUsd,
        amountPercent,
        leverage,
        takeProfit,
        takeProfitPercent,
        stopLoss,
        stopLossPercent,
      },

      set,

      ui: {
        leverageItems,
        percentAmountItems,
        optionalAmountBtnTitle,
        optionalLeverageBtnTitle,
      },

      riskInPercents,
      enableToOpenPosition,
      accountBalance,
      maxPositionAmountUsd,
      activeMarket,
      isFiorinNullableBalance,
    } = usePositionForm(store);

    const { t } = useI18n();

    const isTelegram = computed(() =>
      Boolean(store.getters['telegram/instance'])
    );

    watch(
      () => riskInPercents.value,
      () => {
        //todo: calculate takeProfit and stopLoss in percents or in price
        set.setStopLoss(null);
        set.setTakeProfit(null);
      }
    );

    const marginValueTip = computed(() => {
      const maxAmountValue = activeMarket.value.max_amount_usd;

      return `${t('order.new.titles.max')} ${toCurrencyFormat(
        Math.min(
          activeMarket.value && maxAmountValue / leverage.value,
          Math.max(accountBalance.value.available, accountBalance.value.bounty)
        )
      )}`;
    });

    const leverageTip = computed(() => {
      return `${t('order.new.titles.max')} ${
        (activeMarket.value && activeMarket.value.max_leverage) || 1
      }x`;
    });

    const tabSell = computed(() => {
      if (!activeMarket.value) {
        return '0';
      }

      return toCurrencyFormat(
        activeMarket.value && activeMarket.value.sell,
        null,
        floatList[activeMarket.value.name]
      );
    });

    const tabBuy = computed(() => {
      if (!activeMarket.value) {
        return '0';
      }

      return toCurrencyFormat(
        activeMarket.value && activeMarket.value.buy,
        null,
        floatList[activeMarket.value.name]
      );
    });

    const currentPrice = computed(() => {
      return props.orderType === PositionSides.SELL
        ? activeMarket.value.sell
        : activeMarket.value.buy;
    });

    const entryPriceTip = computed(() => {
      if (!entryPrice.value) {
        return '';
      }

      const current = currentPrice.value;

      if (entryPrice.value < current) {
        const percent = (100 - (+entryPrice.value / +current) * 100).toFixed(1);
        return `ENTRY PRICE <span style="font-family: 'Cantarell_Regular';">${percent}</span>% BELOW MARKET`;
      }
      if (entryPrice.value > current) {
        const percent = (100 - (+current / +entryPrice.value) * 100).toFixed(1);
        return `ENTRY PRICE <span style="font-family: 'Cantarell_Regular';">${percent}</span>% ABOVE MARKET`;
      }
      return '';
    });

    // const entryPriceTip = computed(() => {
    //   return `curr. ${
    //     props.orderType === PositionSides.SELL ? tabSell.value : tabBuy.value
    //   }`;
    // });

    const entryPriceError = computed(() => {
      if (!entryPrice.value) {
        return false;
      }

      if (entryPrice.value === '0') {
        return t('order.new.mustBe', {
          value: `> 0`,
        });
      }
      return false;
    });

    const takeProfitTip = computed(() => {
      if (!amountUsd.value || !takeProfit.value || !leverage.value) {
        return '';
      }
      if (riskInPercents.value) {
        let tpProfit =
          (amountUsd.value * takeProfit.value) / 100 / leverage.value;

        let tpPrice =
          props.orderType === PositionSides.BUY
            ? toCurrencyFormat(
                (tpProfit * (entryPrice.value || +activeMarket.value.buy)) /
                  amountUsd.value +
                  (+entryPrice.value || +activeMarket.value.buy)
              )
            : toCurrencyFormat(
                (
                  (entryPrice.value || +activeMarket.value.sell) -
                  (tpProfit * (+entryPrice.value || +activeMarket.value.sell)) /
                    amountUsd.value
                ).toFixed(activeMarket.value.precision + 1)
              );

        return `<span style="font-family: 'Cantarell_Regular';">${tpPrice}</span>, PROFIT <span style="font-family: 'Cantarell_Regular';">${toCurrencyFormat(
          tpProfit,
          null,
          2
        )}</span>`;
      } else {
        const tpPercent = takeProfitPercent.value;
        const tpProfit =
          (amountUsd.value * takeProfitPercent.value.replace(/\s/, '')) /
          100 /
          leverage.value;

        if (tpPercent === 'Infinity') {
          return '';
        }

        return `<span style="font-family: 'Cantarell_Regular';">${tpPercent}%</span>, PROFIT <span style="font-family: 'Cantarell_Regular';">${toCurrencyFormat(
          tpProfit,
          null,
          2
        )}</span>`;
      }
    });

    const stopLossTip = computed(() => {
      if (!amountUsd.value || !stopLoss.value || !leverage.value) {
        return '';
      }
      if (riskInPercents.value) {
        let sl = stopLoss.value / 100;

        let slPrice =
          props.orderType == PositionSides.BUY
            ? toCurrencyFormat(
                (
                  (entryPrice.value || +activeMarket.value.buy) *
                  (1 - sl / leverage.value)
                ).toFixed(activeMarket.value.precision + 1)
              )
            : toCurrencyFormat(
                (
                  (entryPrice.value || +activeMarket.value.sell) *
                  (1 + sl / leverage.value)
                ).toFixed(activeMarket.value.precision + 1)
              );

        let slLoss = (amountUsd.value * stopLoss.value) / 100 / leverage.value;
        return `<span style="font-family: 'Cantarell_Regular';">${slPrice}</span>, LOSS <span style="font-family: 'Cantarell_Regular';">${toCurrencyFormat(
          slLoss,
          null,
          2
        )}</span>`;
      } else {
        const slPercent = stopLossPercent.value;
        const slLoss =
          (amountUsd.value * stopLossPercent.value) / 100 / leverage.value;
        return `<span style="font-family: 'Cantarell_Regular';">${slPercent}%</span>, LOSS <span style="font-family: 'Cantarell_Regular';">${toCurrencyFormat(
          slLoss,
          null,
          2
        )}</span>`;
      }
    });

    const onEntryPriceNoteClickHandler = async () => {
      let value =
        props.orderType === PositionSides.SELL ? tabSell.value : tabBuy.value;
      value = value.replace(' ', '');
      await set.setEntryPrice(value);
    };

    const isNoteClickable = computed(
      () => true // !amountBtc.value || !amountBtc.value.length
    );

    const onNoteClickLeverage = async () => {
      console.debug(
        '#onNoteClickLeverage #formPosition',
        activeMarket.value.max_leverage
      );

      await set.setLeverage(activeMarket.value.max_leverage);
    };

    let stopLossTimeout = null;

    const hyphen = String.fromCharCode(45);
    const minus = String.fromCharCode(8722);

    const tpslErrorMessage = computed(() => {
      let tpslMessage = ['', ''];

      const hasMinus = (value) => {
        value = value.value?.toString().replace(' ', '') || '';
        const has = value.includes(hyphen) || value.includes(minus);
        return has;
      };

      const tpError = hasMinus(takeProfitPercent) || hasMinus(takeProfit);
      const slError = hasMinus(stopLossPercent) || hasMinus(stopLoss);

      const type = props.orderType;
      if (tpError || slError) {
        clearTimeout(stopLossTimeout);
        stopLossTimeout = null;

        const marketPrice =
          +entryPrice.value ||
          (type === PositionSides.BUY
            ? activeMarket.value.buy
            : activeMarket.value.sell);
        const greaterOrLess = type === PositionSides.BUY ? '> ' : '< ';

        const fractionDigits =
          floatList[activeMarket.value.name] || getPrecision(marketPrice) || 2;

        const recommendedPrice = +marketPrice.toFixed(fractionDigits);
        if (tpError) {
          if (riskInPercents.value) {
            tpslMessage[0] = t('order.new.mustBe', { value: '> 0' });

            return tpslMessage;
          }

          const greaterOrLessValue = greaterOrLess + recommendedPrice;
          tpslMessage[0] = t('order.new.mustBe', { value: greaterOrLessValue });
        }
        if (slError) {
          const greaterOrLess =
            props.orderType === PositionSides.BUY ? '<' : '>';
          const greaterOrLessValue = riskInPercents.value
            ? '> 0%'
            : greaterOrLess + ' ' + (entryPrice.value || recommendedPrice);
          tpslMessage[1] = t('order.new.mustBe', { value: greaterOrLessValue });
        }
      } else if (+stopLossPercent.value > 80) {
        tpslMessage[1] = t('order.new.mustBe', { value: '< 80%' });
        if (!stopLossTimeout) {
          // eslint-disable-next-line vue/no-async-in-computed-properties
          stopLossTimeout = setTimeout(() => {
            if (riskInPercents.value) {
              set.setStopLoss(79);
            } else if (type === PositionSides.BUY) {
              const fractionDigits =
                floatList[activeMarket.value.name] ||
                getPrecision(activeMarket.value.buy) ||
                2;

              set.setStopLoss(
                (
                  (+entryPrice.value || +activeMarket.value.buy) *
                  (1 - 0.79 / +leverage.value)
                ).toFixed(fractionDigits)
              );
            } else if (type === PositionSides.SELL) {
              const fractionDigits =
                floatList[activeMarket.value.name] ||
                getPrecision(activeMarket.value.buy) ||
                2;

              set.setStopLoss(
                (
                  (entryPrice.value || +activeMarket.value.sell) *
                  (1 + 0.79 / +leverage.value)
                ).toFixed(fractionDigits)
              );
            }
            clearTimeout(stopLossTimeout);
            stopLossTimeout = null;
          }, 2000);
        }
      } else {
        clearTimeout(stopLossTimeout);
        stopLossTimeout = null;
      }
      return tpslMessage;
    });

    watch(
      () => {
        const errors = tpslErrorMessage.value;
        const hasError = entryPriceError.value || errors[0] || errors[1];
        return !!hasError;
      },
      (value) => {
        set.setHasError(value);
      }
    );

    const onArrow = (isIncrease) => {
      const curr = currentPrice.value;
      const precision = getPrecisionByPrice(+curr);
      // console.log('precision', precision, 'curr', curr);

      const entry = +entryPrice.value;
      const value = entry || curr;

      let newValue = isIncrease ? value * 1.001 : value * 0.999;

      // we need to do +1 , because 2 decimals is not enough for some pairs
      // this is a hot fix
      newValue = floorNumber(
        newValue,
        (floatList[activeMarket.value.name] || precision) + 1
      );

      set.setEntryPrice(newValue);
    };

    const onArrowLeft = () => {
      onArrow(false);
    };

    const onArrowRight = () => {
      onArrow(true);
    };

    const onExpand = () => {
      set.setIsExpanded(false);
    };

    // setInterval(() => {
    //   console.log(
    //     '#ALL',
    //     '\n',
    //     'amountUsd',
    //     amountUsd.value,
    //     '\n',
    //     'entryPrice',
    //     entryPrice.value,
    //     '\n',
    //     'leverage',
    //     leverage.value,
    //     '\n',
    //     'takeProfit',
    //     takeProfit.value,
    //     '\n',
    //     'stopLoss',
    //     stopLoss.value
    //   );
    // }, 5_000);

    return {
      set,
      onExpand,
      onArrowLeft,
      onArrowRight,
      optionalLeverageBtnTitle,
      optionalAmountBtnTitle,
      leverageItems,
      percentAmountItems,

      maxPositionAmountUsd,
      amountUsd,
      entryPrice,
      leverage,
      stopLoss,
      takeProfit,
      takeProfitTip,
      stopLossTip,
      riskInPercents,
      tpslErrorMessage,
      isNoteClickable,
      marginValueTip,
      leverageTip,
      entryPriceTip,
      entryPriceError,
      // amountMarginTip,
      enableToOpenPosition,
      // eslint-disable-next-line vue/no-dupe-keys
      activeMarket,
      onEntryPriceNoteClickHandler,
      onNoteClickLeverage,
      toUSDMarginFormat,
      onFocus,
      amountPercent,
      showModal,
      modalsByName,
      toggleLeverageEntry,
      isFiorinNullableBalance,
      isTelegram,
    };
  },
};
</script>
<style lang="scss" scoped>
.form-position-expanded {
  width: 100%;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 0;
  padding: 18px 20px 0 26px;
  box-sizing: border-box;
  margin-top: 11px;

  &.isTelegram {
    min-height: initial;
  }

  &.isWeb {
    min-height: max-content;
    padding-bottom: 15px;
  }

  &__title {
    font-size: 14px;
    margin: 14px 0 21px;
    color: #fff; // #888b90;
  }

  &__collapse {
    font-size: 14px;
    margin: 10px 0;
    color: #888b90;
    text-align: center;
    cursor: pointer;

    &.toggleLeverageEntry {
      margin-bottom: 19px;
    }
  }

  .new-field {
    width: 100%;
    margin-top: 12px;
    width: calc(100% + 15px);
    margin-left: -6px;
    max-height: 60px;
    overflow: hidden;

    .field {
      width: 154px;
    }

    &.row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  @media screen and (min-width: 640px) and (max-width: 1023px) {
    justify-content: center;
  }

  .column {
    padding: 5px;
    width: auto;

    @media screen and (min-width: 1024px) {
      width: 143px;
      padding: 0;
    }
  }

  .input-item {
    width: auto;
    height: 50px;
    margin: 20px 10px;

    @media screen and (min-width: 1024px) {
      width: 143px;
      margin: 20px 0;
    }
  }
}

@media (min-width: 481px) {
  .form-position-expanded {
    .new-field {
      .field {
        width: 48.5%;
      }
    }
  }
}

@media (max-width: 480px) {
  .form-position-expanded {
    padding: 0 21px 15px 27px;

    .new-field {
      .field {
        width: 100%;

        &:first-child {
          margin-right: 12px;
        }
      }
    }
  }
}

body.dark {
  .form-position-expanded {
    &__collapse {
      color: #fff;
    }
  }
}
</style>
