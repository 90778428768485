<template>
  <div id="v-step-0" class="markets box">
    <div v-if="false" class="markets__title">
      <Menu v-if="$attrs.isMobile" :mobile="true" :show-logo-only="true" />
      <div v-else class="title">
        {{ parseTitle($t('blocks.titles.markets')) }}
      </div>
      <div class="markets__search">
        <input
          v-model="searchMarketsStr"
          class="text-secondary"
          :class="{ hasValue: searchMarketsStr }"
          @focus="handleFocus"
          @focusout="handleFocusOut"
          :placeholder="$t('markets.search.hint.search')"
          enterkeyhint="done"
        />
        <button
          v-if="searchMarketsStr"
          class="search-icon _cancel"
          @click="clearSearch"
        >
          <icon name="cancel" width="11" height="11" />
        </button>
        <icon v-else name="search" class="search-icon" width="14" height="16" />
      </div>
    </div>
    <div class="markets__search-wrap">
      <SearchInput />
    </div>
    <MarketTabsFilter
      v-if="!isNotDesktop"
      @toggleFilter="onToggleFilter"
      @setTabFromCache="onSetTabFromCache"
      :active-index="activeIndex"
      :is-web="!isNotDesktop"
      @change="onChangeMarketsListFilter"
    />
    <template v-if="isNotDesktop">
      <transition name="fade-height">
        <MarketTabsNew
          v-if="!showOnlyMarkets"
          :active-index="activeIndex"
          @change="onChangeMarketsListFilter"
        />
      </transition>
      <!-- <MarketTabs
        v-if="false"
        @change="onChangeMarketsListFilter"
        :active-index="activeIndex"
      />     -->
      <MarketList
        :filter-by="filterType"
        :actual-markets="filteredMarkets"
        is-mobile
        :loading="loading"
      />
    </template>
    <div v-else class="scroll-list">
      <transition name="fade-height">
        <MarketTabsNew
          v-if="!showOnlyMarkets"
          :active-index="activeIndex"
          @change="onChangeMarketsListFilter"
        />
      </transition>
      <!-- <MarketTabs
        v-if="false"
        @change="onChangeMarketsListFilter"
        :active-index="activeIndex"
      />     -->
      <MarketList
        :filter-by="filterType"
        :is-mobile="isNotDesktop"
        :actual-markets="filteredMarkets"
        :loading="loading"
      />
    </div>
    <MarketTabsFilter
      v-if="isNotDesktop"
      @toggleFilter="onToggleFilter"
      @setTabFromCache="onSetTabFromCache"
      :active-index="activeIndex"
      @change="onChangeMarketsListFilter"
    />
  </div>
</template>
<script>
// import MarketTabs from './components/MarketTabs';
import MarketTabsNew from './components/MarketTabsNew';
import MarketTabsFilter from './components/MarketTabsFilter';
import MarketList from './components/MarketList';
import Menu from '@/components/app/Menu.vue';
import '@/assets/icons/search';

import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { useUAParser } from '@/compositions/useUAParser';
import useChangeFilter from '@/compositions/useChangeFilter';
import { disableScrolling, enableScrolling } from '@/helpers/utils';
import { useScope } from '@/compositions/useScope';

import { MARKETS_TYPES, DEFAULT_MARKET } from '@/helpers/enums';
import { getLocalItem, setLocalItem } from '@/helpers/storage';
import { marketLabel, marketTicker } from '@/config/wrapMarkets';
import SearchInput from '@/components/ui/SearchInput.vue';
// import { useModals } from '@/modules/modals/api';

import { parseTitle } from '@/helpers/ui';
import authMixin from '@/mixins/auth.mixin';

export default {
  mixins: [authMixin],
  components: {
    MarketTabsNew,
    MarketTabsFilter,
    MarketList,
    Menu,
    SearchInput,
  },
  setup() {
    // const { showModal, modalsByName } = useModals();

    const { isNotDesktop } = useScope();

    const store = useStore();
    const UAParser = useUAParser();
    const { onChangeTabIndex, activeIndex } = useChangeFilter();
    const filterType = ref(getLocalItem('marketFilterType') || DEFAULT_MARKET);

    onChangeTabIndex(MARKETS_TYPES.indexOf(filterType.value));

    const showOnlyMarkets = ref([0, 1].includes(activeIndex.value));

    const onToggleFilter = (val) => {
      showOnlyMarkets.value = val;
    };

    onMounted(() => {
      console.log('mounted markets');
    });

    onUnmounted(() => {
      console.log('un mounted markets');
    });

    const searchMarketsStr = ref('');

    const markets = computed(() => store.getters['markets/markets']);
    const loading = computed(() => store.getters['markets/marketsLoading']);

    const favs = computed(() => store.getters['settings/marketFavourites']);

    const onChangeMarketsListFilter = ({ type, index }) => {
      // , autoTrigger
      filterType.value = type;
      onChangeTabIndex(index);
      setLocalItem('marketFilterType', type);

      // if (
      //   // window.document.body.clientWidth <= 480
      //   !autoTrigger // !localStorage.getItem('tutorialMarkets')
      // ) {
      //   // showModal(modalsByName.tutorialHistory);
      //   // localStorage.setItem('tutorialMarkets', true);
      // }
    };

    const actualMarkets = computed(() => {
      const actualMarketsStructure = {};

      if (filterType.value === '_star') {
        (actualMarketsStructure.value = markets.value.filter((market) =>
          favs.value.includes(market.name)
        )),
          (actualMarketsStructure.nested = true);
      } else if (filterType.value === '_fire') {
        actualMarketsStructure.value = markets.value.filter((market) => {
          return (
            Math.abs((market.usdPrice - market.price24h) / market.price24h) >
            0.02
          );
        });
        actualMarketsStructure.nested = false;
      } else {
        (actualMarketsStructure.value = store.getters[
          'markets/getMarketsByGroup'
        ](filterType.value)),
          (actualMarketsStructure.nested = false);
      }

      return actualMarketsStructure;
    });

    const filteredMarkets = computed(() => {
      if (!searchMarketsStr.value) {
        return actualMarkets.value;
      }

      return {
        ...markets.value,
        value: markets.value.filter((market) => {
          const isSameName = market.name
            .toLowerCase()
            .includes(searchMarketsStr.value.toLowerCase());

          const isSameTicker = [
            marketTicker(market.name),
            marketLabel(market.name),
          ].some((value) =>
            value
              .toLowerCase()
              .includes(searchMarketsStr.value.toLocaleLowerCase())
          );

          return isSameName || isSameTicker;
        }),
      };
    });

    const clearSearch = () => {
      searchMarketsStr.value = '';
    };

    const handleFocus = () => {
      if (UAParser.isSafari()) {
        disableScrolling();
      }
    };
    const handleFocusOut = () => {
      if (UAParser.isSafari()) {
        enableScrolling();
      }
    };

    const onSetTabFromCache = (ndx) => {
      onChangeTabIndex(ndx);
    };

    return {
      isNotDesktop,
      parseTitle,
      showOnlyMarkets,
      onToggleFilter,
      filteredMarkets,
      searchMarketsStr,
      actualMarkets,
      onChangeMarketsListFilter,
      filterType,
      clearSearch,
      activeIndex,
      onChangeTabIndex,
      handleFocus,
      handleFocusOut,
      loading,
      onSetTabFromCache,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.markets {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  .scroll-list {
    overflow: auto;
    height: calc(100% - 120px);
  }

  @media screen and (max-width: 1024px) {
    overflow-y: auto;
    max-height: none !important;
    min-height: 100vh;
    height: auto !important;
  }

  &__title {
    padding: 20px 20px 13px 20px;
    min-height: 50px;
    font-family: Gotham_Pro_Medium;
    font-size: 22px; // 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @media screen and (max-width: 640px) {
      height: 50px;
    }
  }

  &__search-wrap {
    width: 100%;
    box-sizing: border-box;
    margin-top: 20px;
    margin-bottom: 5px;
    padding: 0 20px;

    @media screen and (max-width: 1024px) {
      margin-left: 1px;
      width: calc(100% - 1px);
    }

    @media screen and (min-width: 1024px) {
      margin-bottom: 0;
    }
  }

  &__search {
    input {
      width: 105px;
      font-size: 16px;
      overflow: hidden;
      font-family: Gotham_Pro_Regular;
      border: none;
      outline: none;
      text-transform: uppercase;
      background-color: inherit;
      box-sizing: border-box;

      &.hasValue {
        width: 85px;
        margin-right: 20px;
      }

      @media screen and (max-width: 480px) {
        margin-top: 6px;
      }
    }

    .search-icon {
      position: absolute;
      top: 50%;
      right: 21px;
      margin-top: -2px;
      opacity: 0.4;

      @media screen and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 3) {
        margin-top: -7px;
      }

      &._cancel {
        border: none;
        box-shadow: none;
        background: none;
        outline: none;
        width: auto;
        height: 19px;
        margin-top: -2px;
        text-align: center;
        padding: 0 21px 0 5px;
        right: 0;

        @media screen and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 3) {
          margin-top: -9.5px;
        }
      }
    }

    &:hover {
      .search-icon {
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .markets__search .search-icon._cancel {
    margin-top: -4px;
  }

  .markets__search .search-icon {
    margin-top: -4px;
  }
}

@media screen and (max-width: 410px) {
  .markets__search .search-icon._cancel {
    margin-top: -3px;
  }

  .markets__search .search-icon {
    margin-top: -3px;
  }
}

.fade-height-enter-active,
.fade-height-leave-active {
  transition: all 0.25s ease-out;
}

.fade-height-enter-from,
.fade-height-leave-to {
  height: 0;
  margin-top: 0;
  margin-bottom: 0;
  opacity: 0;
}
</style>
