import { getModalFlag } from '@/helpers/storage';
import store from '@/store';
import { watch } from 'vue';

export function useModals() {
  const modalsByName = {
    bountyTrading: 'Bounty-Trading',
    bountySharing: 'Bounty-Sharing',
    bountyReceived: 'Bounty-Received',
    profitRecovery: 'Profit-Recovery',
    accountDeletion: 'Account-Deletion',
    telegramConnect: 'Telegram-Connect',
    marketData: 'Market-Data',
    landing: 'Landing',
    tour: 'Site-Tutorial',
    tradingSession: 'Trading-Session',
    termsAndConditions: 'Terms-and-Conditions',
    login: 'Login',
    logout: 'Logout',
    prohibitedPersons: 'Prohibited-Persons',
    privacyPolicy: 'Privacy-Policy',
    email: 'Email',
    announcements: 'Announcements',
    preLogin: 'PreLogin',
    cookiesPolicy: 'Cookies-Policy',
    KYC: 'AML-Policy',
    riskStatement: 'Risk-Statement-Policy',
    reviews: 'Reviews',
    profitRebate: '_Profit-Deduction',
    positionClose: '_Position-Close-Confirm',
    positionCard: '_Position-Card',
    breakoutTrade: '_Breakout-Trade',
    liquidityMain: 'Liquidity',
    liquidityConfirmRefund: 'Liquidity-Confirm-Refund',
    liquidityAllocation: 'Liquidity-Allocation',
    liquidityProvide: 'Liquidity-Provide',
    liquidityMechanics: 'Liquidity-Mechanics',
    liquidityPaymentReceived: 'Liquidity-Payment-Received',
    liquidityRefund: 'Liquidity-Refund-on-Demand',
    swaps: 'Swaps',
    handCashNotify: 'HandCash-Notify',
    usaAgree: '_Usa-Agree',
    verification: 'KYC-Verification',
    phoneVerification: 'Phone-Verification',
    kycStatus: 'KYC-status',
    lostConnectionStatus: 'Lost-Connection-status',
    subCentProfit: '_Sub-Cent-Profit',
    standaloneAttention: 'Standalone-Attention',
    pwaModal: 'Pwa',
    pwaAndroidPrompt: 'PwaAndroidPrompt',
    transferLiquidity: 'Transfer-Liquidity',
    earnedLiquidity: 'EarnedLiquidity',
    tutorialHistory: 'TutorialHistory',
    openAnyTrade: 'OpenAnyTrade',
    sharePosition: 'SharePosition',
    getBounty: 'GetBounty',
    bountyProfits: 'BountyProfits',
    smallAmount: 'SmallAmount',
    confirmRemovePositions: 'ConfirmRemovePositions',
    unauthorized: 'Unauthorized',
    chooseCollateral: 'Choose-collateral',
  };

  const onModalClosed = (modalName, fn, id) => {
    console.debug(
      '#Modal init onModalClosed',
      'modalName:',
      modalName,
      'id:',
      id
    );

    //ensure that fn is called only once
    let stopped = false;
    let stop = watch(
      () => modalStatus(modalName),
      (value) => {
        if (stopped) {
          return;
        }

        if (!value) {
          stopped = true;
          stop();

          console.debug(
            '#Modal closed',
            'modalName:',
            modalName,
            'value:',
            value,
            'id:',
            id
          );

          fn();
        } else {
          console.debug('#Modal opened', 'modalName:', modalName, 'id:', id);
        }
      },
      { flush: 'post', immediate: false }
    );
  };

  // just for additional tracking in logs
  let modalInstanceId = 0;
  //todo [Vadim] why need all these params? remove?
  const showModal = (modalName, value, { force, usdOnly } = {}) =>
    new Promise((resolve) => {
      const localStorageModalFlag = getModalFlag(modalName);
      const fiorinModalFlag = store.getters['auth/isFiorinMode'];

      const availableForWallet = usdOnly ? fiorinModalFlag : true;

      if ((!localStorageModalFlag || force) && availableForWallet) {
        onModalClosed(modalName, resolve, modalInstanceId++);
        store.dispatch('modals/toggleModal', {
          k: modalName,
          v: value || true,
        });
      }
    });

  const hideModal = (modalName) => {
    store.dispatch('modals/toggleModal', { k: modalName, v: false });
  };
  const modalStatus = (modalName) =>
    store.getters['modals/modalStatus'](modalName) || false;

  return { showModal, hideModal, modalStatus, modalsByName, onModalClosed };
}
