<template>
  <Modal :stop-mobile-max-height="true" to-top @close="onClose">
    <Card
      :description="$t('popups.liquidityFundraiser.titleDesc')"
      :title="
        settings.providedLiquidity
          ? $t('popups.liquidityFundraiser.title2').toUpperCase()
          : $t('popups.liquidityFundraiser.title').toUpperCase()
      "
      class="card liquidity-dashboard-wrap"
      :body-class="'card-full'"
    >
      <div v-if="isLoading" class="wraploader">
        <Loader />
      </div>
      <div
        v-else
        class="card-content"
        :class="{
          standalone:
            wd.navigator.standalone ||
            isRunningStandaloneAndroid() ||
            isTelegram,
        }"
      >
        <ItemLabelValue
          :label="$t('popups.liquidityFundraiser.overTime')"
          :value="newStakeEstimatedProfitLabel"
          :class="{ hide: !+amount }"
        />
        <SimpleInput
          v-if="!$isFiorin"
          new-version
          :value="amount"
          type="text"
          :min-value="0"
          directive="number"
          :title="liqPlaceholder"
          @onChange="onChangeBsvAmount"
          @focus="onFocusInput"
          @focusOut="onFocusOutInput"
        />

        <Selector
          v-else
          description="SELECT POOL CURRENCY"
          description-input="ENTER LIQUIDITY AMOUNT"
          optional-btn="BACK"
          fixed-arrow-width
          :show-carousel="3"
          split-value="-"
          :items="fiorinTokens"
          lefted
          stop-outside
          reset-active-Item
          @focus="onFocusInput"
          @focusOut="onFocusOutInput"
          @selectItem="onSelectFiorinToken"
          @changeInput="onChangeSelectorInput"
        />

        <p class="selector__description f20 mt-24">
          {{ $t('popups.liquidity.usd.index.important2') }}
        </p>
        <div class="liquidity-block__stats-title">
          {{ $t('popups.liquidityFundraiser.poolStatsTitle') }}
        </div>

        <InfoBlockVertPanel
          title="Last 3Y APY"
          value="7.8%"
          description="Based on actual liquidity<br>tranche staked 36 mos. ago"
          class="liquidity-block__stats-total"
        />

        <InfoBlockVert
          title="Liquidity Pool, USD equiv"
          :value="insurancePool"
          class="liquidity-block__stats-block"
        />

        <div class="wrap-row-btn">
          <InfoBlockVert
            :title="`Provided, ${symbolLabel}`"
            :value="
              !+liquidityFunding.totalFunded
                ? 0
                : usdBsvFormatAmount(
                    liquidityFunding.totalFunded,
                    '',
                    true,
                    true
                  )
            "
            class="liquidity-block__stats-block"
          />
          <div
            class="detail-btn"
            @click="showModal(modalsByName.liquidityAllocation)"
          >
            DETAILS
          </div>
        </div>

        <InfoBlockVert
          :title="`Rewards, ${symbolLabel}`"
          :value="
            !+liquidityFunding.totalPaymentReceived
              ? 0
              : usdBsvFormatAmount(
                  liquidityFunding.totalPaymentReceived,
                  '',
                  true,
                  true
                )
          "
          class="liquidity-block__stats-block"
        />
        <InfoBlockVert
          :title="`Next 24H Payment, ${symbolLabel}`"
          :value="
            !+totalNextPayment
              ? 0
              : usdBsvFormatAmount(totalNextPayment, '', true, true, false)
          "
          class="liquidity-block__stats-block"
        />
        <div
          v-if="!+liquidityFunding.userFunded"
          class="liquidity-block__stats-separator"
        />
        <template v-if="+liquidityFunding.userFunded">
          <div class="liquidity-block__stats-separator" />
          <div class="wrap-row-btn">
            <InfoBlockVert
              :title="`You Provided, ${symbolLabel}`"
              :value="!+liquidityFunding.userFunded ? 0 : userFunded"
              class="liquidity-block__stats-block"
            />
            <!-- <div class="icon-next" @click="clickUserProvisions" /> -->
            <div
              class="detail-btn"
              @click="showModal(modalsByName.liquidityProvide)"
            >
              DETAILS
            </div>
          </div>
          <div class="wrap-row-btn">
            <InfoBlockVert
              :title="`You Received, ${symbolLabel}`"
              :value="
                !+liquidityFunding.userPaymentReceived
                  ? 0
                  : usdBsvFormatAmount(userReceived, '', true, true)
              "
              class="liquidity-block__stats-block"
            />
            <!-- <div class="icon-next" @click="clickUserProvisions" /> -->
            <div
              class="detail-btn"
              @click="showModal(modalsByName.liquidityPaymentReceived)"
            >
              DETAILS
            </div>
          </div>
          <div class="wrap-row-btn">
            <InfoBlockVert
              :title="`Your Next 24H Payment`"
              :value="
                !+userNextPayment
                  ? 0
                  : usdBsvFormatAmount(userNextPaymentBase, '', true, true)
              "
              class="liquidity-block__stats-block"
            />
          </div>
          <div class="wrap-row-btn">
            <InfoBlockVert
              :title="$t('popups.liquidityFundraiser.overTime')"
              :value="`${userReturnWithSymbol} / +${userReturnPercentFormatted}%`"
              class="liquidity-block__stats-block"
            />
          </div>
          <div class="liquidity-block__stats-separator" />
        </template>
        <template v-if="!$isFiorinMode">
          <p class="selector__description f20">
            {{ $t('popups.liquidity.usd.index.important3') }}
            <span
              @click="showModal(modalsByName.liquidityAllocation)"
              class="decoration--underlined underline"
              >{{ $t('popups.liquidityFundraiser.learn') }}
            </span>
          </p>
          <p class="f20">
            {{ $t('popups.liquidity.usd.index.important') }}
            <span
              @click="showModal(modalsByName.liquidityMechanics)"
              class="decoration--underlined underline"
              >{{ $t('popups.liquidityFundraiser.learn') }}</span
            >
          </p>
        </template>
        <template v-else>
          <p class="selector__description f20">
            {{ $t('popups.liquidity.usd.index.important3') }}
            <span
              @click="showModal(modalsByName.liquidityAllocation)"
              class="decoration--underlined underline"
              >{{ $t('popups.liquidityFundraiser.learn') }}
            </span>
          </p>
          <p v-if="!fiorinToken || fiorinToken?.title !== 'BSV'" class="f20">
            {{ $t('popups.liquidity.usd.index.important4') }}
            <span
              @click="showModal(modalsByName.liquidityMechanics)"
              class="decoration--underlined underline"
              >{{ $t('popups.liquidityFundraiser.learn') }}</span
            >
          </p>
          <p v-else class="f20">
            {{ $t('popups.liquidity.usd.index.important') }}
            <span
              @click="showModal(modalsByName.liquidityMechanics)"
              class="decoration--underlined underline"
              >{{ $t('popups.liquidityFundraiser.learn') }}</span
            >
          </p>
        </template>
        <a
          style="text-decoration: none"
          href="https://youtu.be/hBHJeMk1ecs"
          rel="noopener noreferrer"
          target="_blank"
        >
          <div class="liquidity-block__link-block first">
            <IndicatorValue web-paddings title="Introduction video" />
          </div>
        </a>
        <a
          style="text-decoration: none"
          href="https://pool.dxs.app"
          rel="noopener noreferrer"
          target="_blank"
        >
          <div class="liquidity-block__link-block">
            <IndicatorValue web-paddings title="Liquidity teaser" />
          </div>
        </a>
        <a
          style="text-decoration: none"
          href="https://faq.dxs.app/getting-started/get-started-as-a-liquidity-provider"
          rel="noopener noreferrer"
          target="_blank"
        >
          <div class="liquidity-block__link-block">
            <IndicatorValue web-paddings title="Get started guide" />
          </div>
        </a>
        <div
          class="liquidity-block__link-block"
          @click="showModal(modalsByName.liquidityAllocation)"
        >
          <IndicatorValue web-paddings title="Liquidity rounds" />
        </div>
        <div
          class="liquidity-block__link-block"
          @click="showModal(modalsByName.liquidityRefund)"
        >
          <IndicatorValue web-paddings title="Refund on demand" />
        </div>
        <a
          style="text-decoration: none"
          href="https://faq.dxs.app/policies/liquidity-provider-agreement"
          rel="noopener noreferrer"
          target="_blank"
        >
          <div class="liquidity-block__link-block">
            <IndicatorValue web-paddings title="Liquidity provider agreement" />
          </div>
        </a>
        <div class="selector__description text-secondary mt-15 mb-15">
          {{ $t('popups.liquidityFundraiser.disclaimer3') }}
        </div>
      </div>
      <Button
        v-if="!isLoading"
        :accent="!!+amount"
        :text="
          +liquidityFunding.userFunded
            ? $t('popups.liquidityFundraiser.provideButton2')
            : $t('popups.liquidityFundraiser.title')
        "
        type="secondary"
        :class="{
          isFocusInput,
          standalone:
            wd.navigator.standalone ||
            isRunningStandaloneAndroid() ||
            isTelegram,
        }"
        class="btn liq"
        @click="provideLiquidity"
      />
    </Card>
    <LiquidityProvideModal
      v-if="modalStatus(modalsByName.liquidityProvide)"
      :total-liquidity="totalLiquidityProvided"
      :your-liquidity="`${
        !+liquidityFunding.userFunded ? 0 : userFunded
      } ${symbolLabel}`"
      :user-profit-percent="userReturnPercentFormatted"
      :user-return="userReturnWithSymbol"
      @showRefundModal="
        () => {
          showModal(modalsByName.liquidityRefund);
          hideModal(modalsByName.liquidityProvide);
        }
      "
      @showMechanics="
        () => {
          showModal(modalsByName.liquidityMechanics);
          hideModal(modalsByName.liquidityProvide);
        }
      "
      @showAllocationModal="
        () => {
          showModal(modalsByName.liquidityAllocation);
          hideModal(modalsByName.liquidityProvide);
        }
      "
      @close="hideModal(modalsByName.liquidityProvide)"
    />
    <PaymentReceivedModal
      v-if="modalStatus(modalsByName.liquidityPaymentReceived)"
      :liquidity-fund="liquidityFunding"
      :receive-value="userReceived"
      :symbol-label="symbolLabel"
      :user-provisions-keys="userProvisionsKeys"
      @close="hideModal(modalsByName.liquidityPaymentReceived)"
    />
    <RefundOnDemandModal
      v-if="modalStatus(modalsByName.liquidityRefund)"
      @showMechanics="
        () => {
          showModal(modalsByName.liquidityMechanics);
          hideModal(modalsByName.liquidityRefund);
        }
      "
      @showAllocationModal="
        () => {
          showModal(modalsByName.liquidityAllocation);
          hideModal(modalsByName.liquidityRefund);
        }
      "
      @close="hideModal(modalsByName.liquidityRefund)"
    />

    <LiquidityAllocationModal
      v-if="modalStatus(modalsByName.liquidityAllocation)"
      @showRefundModal="
        () => {
          showModal(modalsByName.liquidityRefund);
          hideModal(modalsByName.liquidityAllocation);
        }
      "
      @showMechanics="
        () => {
          showModal(modalsByName.liquidityMechanics);
          hideModal(modalsByName.liquidityAllocation);
        }
      "
      @close="hideModal(modalsByName.liquidityAllocation)"
    />
    <MechanicsModal
      v-if="modalStatus(modalsByName.liquidityMechanics)"
      @showRefundModal="
        () => {
          showModal(modalsByName.liquidityRefund);
          hideModal(modalsByName.liquidityMechanics);
        }
      "
      @showAllocationModal="
        () => {
          showModal(modalsByName.liquidityAllocation);
          hideModal(modalsByName.liquidityMechanics);
        }
      "
      @close="hideModal(modalsByName.liquidityMechanics)"
    />
    <ConfirmPaymentModal
      v-if="showConfirmPaymentModal"
      :fiorin-token="fiorinToken"
      :amount-bsv="amount"
      :fiorin-currency="
        isTokenIdCurrencyStable ? tokenIdCurrency?.toUpperCase() : ''
      "
      :rounds-items="newStakeEstimate.rounds"
      :return-value="newStakeEstimatedProfitLabel"
      @successPayment="
        () => {
          notify({
            text: $t('popups.liquidityFundraiser.titles.liqProvided'),
            type: 'info',
          });
          onSuccessTransfer();
          showConfirmPaymentModal = false;
          acceptMode = false;
        }
      "
      @close="
        () => {
          showConfirmPaymentModal = false;
          acceptMode = false;
        }
      "
    />
    <TransferLiquidity
      v-if="modalStatus(modalsByName.transferLiquidity)"
      :transfers-list="transfersList"
      @success="onSuccessTransfer"
      @close="hideModal(modalsByName.transferLiquidity)"
    />
  </Modal>
</template>
<script>
import { ref, computed, onMounted, defineComponent, watch } from 'vue';
import notify from '@/plugins/notify';
import bsvPrice from '@/compositions/bsvPrice';
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import SimpleInput from '@/components/ui/SimpleInput';

import {
  toCurrencyFormat,
  bsvFormat2,
  bsvFormat,
  usdBsvFormatAmount,
} from '@/helpers/utils';
import ItemLabelValue from '@/components/ui/ItemLabelValue';
import PaymentReceivedModal from './components/PaymentReceivedModal';
import RefundOnDemandModal from './components/RefundOnDemandModal';
import LiquidityAllocationModal from './components/LiquidityAllocationModal';
import LiquidityProvideModal from './components/LiquidityProvideModal';
import ConfirmPaymentModal from './components/ConfirmPaymentModal';
import MechanicsModal from './components/MechanicsModal';
import { connApp, getErrorDetails } from '@/helpers/signalR';
import debounce from 'lodash/debounce';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useModals } from '@/modules/modals/api';
import Loader from '@/components/ui/Loader';
import { loadAllSettings } from '@/modules/settings/api';
import useBalance from '@/compositions/useBalance';
import TransferLiquidity from '@/components/modals/TransferLiquidity';
import { useMarketPriceForMargin } from '@/compositions/useMarketPriceForMargin';
import Selector from '@/components/ui/Selector';
import InfoBlockVert from '@/components/ui/InfoBlockVert';
import InfoBlockVertPanel from '@/components/ui/InfoBlockVertPanel';
import IndicatorValue from '@/components/ui/IndicatorValue';
import '@/assets/icons/backforward';
import { isRunningStandaloneAndroid } from '@/helpers/detects';
import authMixin from '@/mixins/auth.mixin';
import useAuth from '@/compositions/useAuth';

const EVENTS = {
  close: 'close',
};

export default defineComponent({
  name: 'LiquidityModule',
  mixins: [authMixin],
  components: {
    InfoBlockVert,
    InfoBlockVertPanel,
    IndicatorValue,
    Selector,
    Modal,
    Card,
    Loader,
    SimpleInput,
    Button,
    MechanicsModal,
    LiquidityAllocationModal,
    RefundOnDemandModal,
    PaymentReceivedModal,
    ConfirmPaymentModal,
    LiquidityProvideModal,
    ItemLabelValue,
    TransferLiquidity,
  },
  emits: [EVENTS.close],
  setup(_, { emit }) {
    const auth = useAuth();
    const isFiorin = auth.isFiorin.value;
    const isHandCash = auth.isHandCash.value;
    const isFiorinAsProxy = auth.isFiorinAsProxy.value;

    const { t } = useI18n();
    const store = useStore();
    const acceptMode = ref(false);
    const showConfirmPaymentModal = ref(false);
    const isLoading = ref(false);
    const { fetchWalletBalance } = useBalance();
    const transfersList = ref([]);
    const fiorinToken = ref(null);

    // todo: [Vadim] why need both selectorInput and amount?
    const selectorInput = ref('');
    const isFocusInput = ref(false);

    const { modalStatus, showModal, hideModal, modalsByName } = useModals();

    const activeTokenAmountUsd = computed(() => {
      if (isFiorin) {
        return store.getters['fiorin/maxBalanceToken/amountUsd'];
      } else if (isHandCash) {
        return store.getters['handcash/amountUsd'];
      }
      return 0;
    });

    const activeTokenAmount = computed(() => {
      if (isFiorin) {
        return store.getters['fiorin/maxBalanceToken/amount'];
      } else if (isHandCash) {
        return store.getters['handcash/amountBsv'];
      }
      return null;
    });

    const tokenIdCurrency = computed(() => {
      if (isFiorin) {
        return store.getters['fiorin/maxBalanceToken/currency'];
      }

      return null;
    });

    const { marketPriceFromFiorinCurrency } =
      useMarketPriceForMargin(tokenIdCurrency);

    const isTokenIdCurrencyStable = computed(() => {
      if (isFiorin) {
        return store.getters['fiorin/maxBalanceToken/isStable'];
      }

      return false;
    });

    const fiorinTokens = computed(() => {
      if (!isFiorin) {
        return [];
      }

      const tokens = store.getters['fiorin/activeTokens'];
      if (!tokens) {
        return [];
      }

      // console.log('tokens #liquidity', tokens);

      return tokens
        .filter((item) => item.name !== 'USDXS')
        .map((item) => {
          return {
            ...item,
            title: item.name,
            amount: +item.amount,
          };
        });
    });

    const onSelectFiorinToken = (value) => {
      if (!value || !value.title) {
        console.error(
          'bad event value: #onSelectFiorinToken #liquidity',
          value
        );
        return;
      }
      console.debug(
        'onSelectFiorinToken #liquidity',
        value,
        fiorinTokens.value
      );
      const find = fiorinTokens.value.find((t) => t.name === value.title);

      if (find) {
        fiorinToken.value = find;

        // setTimeout(() => {
        //   const input = document.querySelector(
        //     '.liquidity-dashboard-wrap .input__input'
        //   );
        //   if (input) {
        //     input.setAttribute('autofocus', true);
        //     input.focus();
        //   }
        // }, 200);
      } else {
        console.debugError('#liquidity fiorinToken not found:', value);
        fiorinToken.value = null;
      }
    };

    const onChangeSelectorInput = (value) => {
      selectorInput.value = value;
      amount.value = value;
      onInputChangeDebounced(amount.value, fiorinToken.value?.name);
      console.debug('#liquidity value input', selectorInput.value);
      if (!selectorInput.value) {
        // fiorinToken.value = null;
      }
    };

    const provide = () => {
      if (!fiorinToken.value) {
        notify({ text: 'Choose currency and enter amount', type: 'info' });
        document.querySelector(
          '.liquidity-dashboard-wrap .card-content'
        ).scrollTop = 0;
        return;
      }

      if (!+selectorInput.value) {
        notify({ text: 'Choose currency and enter amount', type: 'info' });
        document.querySelector(
          '.liquidity-dashboard-wrap .card-content'
        ).scrollTop = 0;
        return;
      }

      if (+selectorInput.value > +fiorinToken.value.amount) {
        notify({
          text: `Insufficient funds`,
          type: 'info',
        });
        return;
      }

      showConfirmPaymentModal.value = true;
    };

    const sessionInfo = computed(() => store.getters['session/info']);

    const insurancePool = computed(() => {
      const pool = sessionInfo.value.insurancePool;

      if (!pool) {
        return 0;
      }
      return usdBsvFormatAmount(pool, '', true, true);
    });

    // const liquidityFunding = ref({});
    const newStakeEstimate = ref({});

    const liquidityFunding = computed(
      () => store.getters['liquidity/liquidityInfo']
    );

    const userProvisionsKeys = computed(() => {
      return Object.keys(liquidityFunding.value?.userFundedByCurrency || {});
    });
    const userProvisionsKeysIndex = ref(0);

    const symbolLabel = computed(() => {
      const currency = liquidityFunding.value.currency;
      const currencyLower = currency.toLowerCase();

      const isUsd =
        currencyLower.startsWith('usd') || currencyLower.startsWith('dai');
      return isUsd ? 'USD' : currency;
    });

    const userFundedValue = computed(() => {
      if (!+liquidityFunding.value.userFunded) {
        return 0;
      }

      if (userProvisionsKeys.value.length > 1) {
        return liquidityFunding.value.userFunded;
      }

      return liquidityFunding.value?.userFundedByCurrency[
        userProvisionsKeys.value[0]
      ];
    });

    const userReceivedValue = computed(() => {
      if (!+liquidityFunding.value?.userPaymentReceived) {
        return 0;
      }

      if (userProvisionsKeys.value.length > 1) {
        return liquidityFunding.value?.userPaymentReceived;
      }

      return liquidityFunding.value?.userPaymentReceivedByCurrency[
        userProvisionsKeys.value[0]
      ];
    });

    const userFunded = computed(() => {
      return usdBsvFormatAmount(userFundedValue.value, '', true, true);
    });

    const userReceived = computed(() => {
      return usdBsvFormatAmount(userReceivedValue.value, '', true, true);
    });

    const userNextPaymentBase = computed(() => {
      if (userProvisionsKeys.value.length > 1) {
        return usdBsvFormatAmount(
          liquidityFunding.value?.userNextPayment,
          '',
          true,
          true
        );
      }
      return usdBsvFormatAmount(
        liquidityFunding.value?.userNextPaymentByCurrency[
          userProvisionsKeys.value[0]
        ] || 0,
        '',
        true,
        true
      );
    });

    const amount = ref('');

    const settings = computed(() => {
      return store.getters['settings/settings'];
    });

    // todo: [Vadim] check unused
    const amountBsvTip = computed(() => {
      if (!amount.value) {
        return '';
      }

      if (isFiorin && isTokenIdCurrencyStable.value) {
        return `${toCurrencyFormat(
          amount.value * marketPriceFromFiorinCurrency.value
        )} USD`;
      }

      if (isFiorin) {
        return '';
      }

      return `$${toCurrencyFormat(amount.value * bsvPrice(), 2)}`;
    });

    const provideLiquidity = async () => {
      if (isFiorin) {
        provide();
        return;
      }

      if (!+amount.value) {
        notify({ text: t('order.new.toasts.enterAmount'), type: 'info' });

        const input = document.querySelector(
          '.liquidity-dashboard-wrap .input__input'
        );

        input.focus();

        return;
      }

      if (isFiorin) {
        if (+amount.value * marketPriceFromFiorinCurrency.value < 0.01) {
          notify({
            text: 'amount must be greater than or equal to 0.01',
            type: 'info',
          });
          return;
        }

        if (isTokenIdCurrencyStable.value) {
          if (
            +amount.value * marketPriceFromFiorinCurrency.value >
            activeTokenAmountUsd.value
          ) {
            notify({
              text: `Insufficient funds`,
              type: 'info',
            });
            return;
          }
        } else {
          // todo: [Vadim] check currencies
          if (+amount.value > activeTokenAmountUsd.value) {
            notify({
              text: `Insufficient funds`,
              type: 'info',
            });
            return;
          }
        }
      } else {
        if (+amount.value < 0.01) {
          notify({
            text: 'amount must be greater than or equal to 0.01',
            type: 'info',
          });
          return;
        }

        if (+amount.value > +activeTokenAmount.value) {
          notify({
            text: `Insufficient funds`,
            type: 'info',
          });
          return;
        }
      }

      if (!isFiorin) {
        showConfirmPaymentModal.value = true;

        return;
      }

      await loadAllSettings('provideLiquidity');

      const phoneConfirmed =
        store.getters['settings/settings']?.phoneNumberConfirmed;
      const kycConfirmed =
        store.getters['settings/settings']?.kycStatus === 'Accepted';
      const totalLiquidityFund =
        +amount.value + liquidityFunding.value.userFunded;

      if (totalLiquidityFund > 10000 && !kycConfirmed) {
        store.dispatch('burger/verification');

        return;
      }

      if (totalLiquidityFund >= 1000 && !phoneConfirmed) {
        store.dispatch('burger/phoneVerification');

        return;
      }

      showConfirmPaymentModal.value = true;
    };

    const onInputChangeDebounced = debounce(async function (amount, currency) {
      if (!amount) {
        // console.error('amount not passed #liquidity');
        return;
      }

      if (!currency) {
        console.error('currency not passed #liquidity');
        return;
      }

      let error;
      let result = await connApp
        .invokeWithRetry('GetLiquidityEstimatedSplit', +amount, currency)
        .catch((err) => {
          error = getErrorDetails(err);
        });
      if (!error) {
        newStakeEstimate.value = result;
      } else {
        notify({ text: error.message, type: 'info' });
      }
    }, 400);

    const fetchLiquidityTransferableList = async () => {
      let error;
      let result = await connApp
        .invokeWithRetry('GetLiquidityTransferableUsers')
        .catch((err) => {
          error = getErrorDetails(err);
        });

      if (!error) {
        transfersList.value = result;
      } else {
        notify({ text: error.message, type: 'info' });
      }
    };

    const onChangeBsvAmount = (value) => {
      amount.value = value;
      onInputChangeDebounced(amount.value, 'BSV');
    };

    const formatLiquidityAmount = (amount, fractionDigits = 2) => {
      const formatedAmount = +bsvFormat(amount).replace(/ /g, '');
      if (formatedAmount.toFixed(2) === '0.00' && formatedAmount > 0) {
        return formatedAmount.toFixed(5) + ` ${this.$currency}`;
      } else {
        return formatedAmount.toFixed(fractionDigits) + ` ${this.$currency}`;
      }
    };

    const userLiquidityProvided = computed(() =>
      formatLiquidityAmount(liquidityFunding.value.userFunded)
    );

    const newStakeEstimatedProfitLabel = computed(() => {
      const returnFormatted = toCurrencyFormat(
        newStakeEstimate.value.return || 0,
        null,
        2
      );

      const profitPercentValue =
        (100 * +newStakeEstimate.value.return) / +amount.value;

      const profitPercent =
        profitPercentValue == Infinity || isNaN(profitPercentValue)
          ? '0'
          : toCurrencyFormat(profitPercentValue, null, 0) || '0';

      return amount.value ? `${returnFormatted} / +${profitPercent}%` : '-';
    });

    const paymentReceived = computed(() =>
      toCurrencyFormat(liquidityFunding.value.userPaymentReceived, null, 2)
    );
    const totalLiquidityProvided = computed(() =>
      toCurrencyFormat(liquidityFunding.value.totalFunded, null, 2)
    );
    const totalPaymentsReceived = computed(() =>
      toCurrencyFormat(liquidityFunding.value.totalPaymentReceived, null, 2)
    );
    const totalNextPayment = computed(() =>
      toCurrencyFormat(liquidityFunding.value.totalNextPayment, null, 2)
    );
    const userNextPayment = computed(() =>
      toCurrencyFormat(liquidityFunding.value.userNextPayment, null, 2)
    );

    const userReturnValue = computed(
      () =>
        liquidityFunding.value.userPaymentReceived +
        liquidityFunding.value.userPaymentLeft -
        liquidityFunding.value.userFunded
    );

    const userReturnWithSymbol = computed(
      () => bsvFormat2(userReturnValue.value) + ` ${symbolLabel.value}`
    );

    const userReturnPercentFormatted = computed(
      () =>
        toCurrencyFormat(
          (100 * userReturnValue.value) / liquidityFunding.value.userFunded,
          null,
          0
        ) || 0
    );

    const onSuccessTransfer = () => {
      amount.value = '';

      refreshData();

      setTimeout(async () => {
        fetchWalletBalance();
      }, 2_000);

      setTimeout(async () => {
        fetchWalletBalance();
      }, 5_000);

      setTimeout(async () => {
        fetchWalletBalance();
      }, 10_000);

      setTimeout(async () => {
        fetchWalletBalance();
      }, 20_000);
    };

    const getTokens = () => {
      // todo: [Vadim] remove this usage
    };

    const refreshData = async () => {
      isLoading.value = true;
      await store.dispatch('liquidity/fetchLiquidityInfo');
      if (!isFiorinAsProxy) {
        await fetchLiquidityTransferableList();
      }
      setTimeout(() => {
        isLoading.value = false;
      }, 1000);
    };

    onMounted(async () => {
      if (auth.isLoggedIn.value) {
        getTokens();
        await refreshData();
      }
    });

    watch(
      () => auth.isLoggedIn.value,
      async (value) => {
        if (value) {
          await refreshData();
        }
      }
    );

    const liqPlaceholder = computed(() => {
      if (isFiorin) {
        if (isTokenIdCurrencyStable.value) {
          return t('popups.liquidityFundraiser.liqAmount', {
            symbol: tokenIdCurrency.value.toUpperCase(),
          });
        }

        return t('popups.liquidityFundraiser.liqAmount', {
          symbol: 'USD',
        });
      }

      return t('popups.liquidityFundraiser.liqAmount', {
        symbol: 'BSV',
      });
    });

    const onFocusInput = () => {
      // if (window.document.body.clientWidth <= 480) {
      //   isFocusInput.value = true;
      //   setTimeout(() => {
      //     const topBtnPosition = window.visualViewport.height - 90;
      //     document.querySelector('.btn.liq').style.top = topBtnPosition + 'px';
      //     document.querySelector('.btn.liq').style.zIndex = 10;
      //   }, 600);
      // }
    };

    const onFocusOutInput = () => {
      // if (window.document.body.clientWidth <= 480) {
      //   isFocusInput.value = false;
      //   document.querySelector('.btn.liq').style.top = 'auto';
      //   document.querySelector('.btn.liq').style.zIndex = 'unset';
      // }
    };

    const onClose = () => {
      fiorinToken.value = null;
      emit(EVENTS.close);
    };

    const isTelegram = computed(() =>
      Boolean(store.getters['telegram/instance'])
    );

    return {
      isFocusInput,
      onFocusOutInput,
      onFocusInput,
      notify,
      liqPlaceholder,
      transfersList,
      onSuccessTransfer,
      amount,
      amountBsvTip,
      modalStatus,
      showModal,
      hideModal,
      modalsByName,
      onChangeBsvAmount,
      toCurrencyFormat,
      onInputChangeDebounced,
      isLoading,
      onClose,

      userLiquidityProvided,
      paymentReceived,
      userNextPayment,
      userReturnPercentFormatted,
      userReturnWithSymbol,
      newStakeEstimatedProfitLabel,

      totalLiquidityProvided,
      totalPaymentsReceived,
      totalNextPayment,

      liquidityFunding,
      newStakeEstimate,
      acceptMode,
      showConfirmPaymentModal,
      provideLiquidity,

      settings,
      EVENTS,
      tokenIdCurrency,
      isTokenIdCurrencyStable,
      usdBsvFormatAmount,
      insurancePool,
      fiorinTokens,
      fiorinToken,
      onSelectFiorinToken,
      onChangeSelectorInput,

      userProvisionsKeys,
      userProvisionsKeysIndex,
      symbolLabel,
      userFunded,
      userReceived,
      userNextPaymentBase,
      wd: window,
      isRunningStandaloneAndroid,
      isTelegram,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.liquidity-dashboard-wrap {
  font-family: Gotham_Pro_Regular;
  // NEW

  .wrap-row-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  .detail-btn {
    width: 112px;
    height: 44px;
    position: absolute;
    right: -5px;
    top: -3px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(205, 203, 203, 0.8);
    color: rgba(0, 0, 0, 0.8);
    font-family: Gotham_Pro_Medium;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  .hide {
    opacity: 0;
  }

  .liquidity-block {
    overflow-x: hidden;

    &__stats-total {
      margin-left: -20px;
      margin-bottom: 25px;
      width: calc(100% + 40px);
      padding: 15px 20px;

      @media screen and (min-width: 1024px) {
        margin-left: -40px;
        width: calc(100% + 80px);
        padding: 15px 40px;
      }
    }

    &__link-block {
      margin-left: -20px;
      width: calc(100% + 40px);

      @media screen and (min-width: 1024px) {
        margin-left: -40px;
        width: calc(100% + 80px);
      }

      &.first {
        border-top: 1px solid #c3c3c3;
        margin-top: 20px;
      }
    }

    &__stats-separator {
      margin-left: -20px;
      margin-bottom: 24px;
      background: #bcbcbe;
      width: calc(100% + 40px);
      min-height: 10px;

      @media screen and (min-width: 1024px) {
        margin-left: -40px;
        width: calc(100% + 80px);
      }
    }

    &__stats-title {
      font-size: 15px;
      color: #888;
      font-family: Gotham_Pro_Bold;
      text-transform: uppercase;
      margin-top: 40px;
      margin-bottom: 13px;
    }

    &__stats-block {
      margin-bottom: 24px;
    }
  }

  .selector__description {
    font-size: 15px;
    text-align: left;
    line-height: 20px;

    &:first-child {
      margin-top: 24px;
    }

    span {
      cursor: pointer;
      text-decoration: underline;
      text-decoration-color: #444;
    }
  }

  // OLD

  .wraploader {
    width: 100%;
    display: flex;
    min-height: 460px;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .align-right {
    font-size: 12px;
    text-align: right;
    margin-top: -10px;
  }

  p {
    line-height: 18px;

    span.underline {
      text-decoration-line: underline;
      text-decoration-thickness: 1px;
      cursor: pointer;
    }
  }

  .mt-15 {
    margin-top: 15px;
  }

  .f20 {
    line-height: 20px;
    font-size: 15px;

    &.mt-24 {
      margin-top: 24px;
    }
  }

  .head-title {
    margin-bottom: 1rem;
    font-family: Gotham_Pro_Bold;
    margin-top: 0.5rem;
    margin-bottom: 20px;
  }

  .card-content {
    max-height: calc(100% - 125px);
    overflow-y: auto;
    margin-bottom: 62px;
    padding-right: 20px;
    padding-left: 20px;
    box-sizing: border-box;
    overflow-x: hidden;

    &.standalone {
      max-height: calc(100% - 172px);
    }

    @media screen and (min-width: 1024px) {
      max-height: calc(100vh - 320px) !important;
      padding-right: 40px;
      padding-left: 40px;
    }

    @media screen and (min-width: 1800px) {
      max-height: calc(100vh - 380px) !important;
    }

    .mt-15 {
      margin-top: 25px;
    }

    .mb-15 {
      margin-bottom: 15px;
    }
  }

  .btn {
    margin-top: 30px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    &.isFocusInput {
      position: fixed;
      left: 0;
    }

    @media screen and (max-width: 1024px) {
      bottom: 50px;

      &.standalone {
        bottom: 82px;
      }
    }
  }
}

body.dark {
  .liquidity-dashboard-wrap {
    .detail-btn {
      color: #fff;
      background: #2a2b3f;
    }

    .liquidity-block {
      &__stats-separator {
        background: #262c3c;
      }

      &__link-block {
        &.first {
          border-top: 1px solid rgba(255, 255, 255, 0.1);
        }
      }
    }
  }
}
</style>
