<template>
  <div
    id="v-step-5"
    :class="{
      opened: !!positionType,
      standalone:
        wd.navigator.standalone || isRunningStandaloneAndroid() || isIOSTg,
      showBackControls: !showBackControls,
      isTelegram: isIOSTg,
      useMobileLayout: useMobileLayout,
    }"
    class="form-position"
  >
    <div
      v-if="$isMobile && isFormShown && activeMarket?.pair"
      style="
        text-align: center;
        height: 60px;
        opacity: 0.4;
        font-size: 0.9375rem;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      SHOW
      {{
        activeMarket.pair
          .replace('-' + activeMarket.base, '/' + activeMarket.base)
          .toUpperCase()
      }}
      CHART
    </div>

    <!--    {{ activeMarket }}-->

    <div class="ignoreEventTracking">
      <div class="box">
        <FormPositionBuySell
          :form-type="positionType"
          :closed-market="closedMarket"
          :price-buy="priceBuy"
          :price-sell="priceSell"
          :hide-title="formExpanded ? 'COLLAPSE' : 'HIDE'"
          @onInactiveBuySell="onInactiveBuySell"
          @onConnectionLost="onConnectionLost"
          @onHidden="onHidden"
        />
        <FormPositionSimpleForm
          v-if="!!positionType && !formExpanded && isWorkingMarket"
        />
        <FormPositionExpandedForm
          v-if="!!positionType && formExpanded && isWorkingMarket"
          :is-bounty="isBountyPosition && !bountyPaused"
          :orderType="positionType"
        />
        <div
          v-if="!!positionType"
          :class="[positionType]"
          class="form-position__controls"
        >
          <div
            v-if="false"
            class="form-position__controls-btn back"
            @click="backToMarkets"
          >
            <icon name="arrowleft" width="20" height="20" @click="() => {}" />
          </div>
          <div
            :class="{
              disabled: !+formData.amountUsd || tpslError,
              mobile: $isMobile,
            }"
            class="form-position__controls-btn open"
            @click="confirmOperation"
          >
            {{ positionActionTitle }}
          </div>
        </div>
        <Modal
          v-if="showConfirmModal"
          :stop-mobile-max-height="true"
          to-top
          @close="showConfirmModal = false"
        >
          <Card
            title-class="capitalize"
            :title="$t('popups.orderConfirm.titleNew')"
            :description="orderCardDescrition"
            :description-green="isLong"
            :description-red="isShort"
            :replace-str-upper="marketTicker(activeMarket.name)"
          >
            <FormPositionConfirm
              :is-bounty="isBountyPosition && !bountyPaused"
              :active-market="activeMarket"
              :orderType="positionType"
              :formData="formData"
              @payment="onPayment"
              @error="onError"
              @on-close-order-confirm="onClose"
            />
          </Card>
        </Modal>

        <ModalMarketOpenClose
          v-if="showMarketOpenClose"
          :hide-again-btn="true"
          :value="workHour"
          @close="
            showMarketOpenClose = false;
            workHour = '';
          "
        />
        <ModalConnectionLost
          v-if="showConnectionLost"
          @close="showConnectionLost = false"
        />
        <HelloDXS v-if="showDXSModal" @close="showDXSModal = false" />
      </div>
    </div>
  </div>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import FormPositionBuySell from './components/FormPositionBuySell';
import FormPositionSimpleForm from './components/FormPositionSimpleForm';
import FormPositionExpandedForm from './components/FormPositionExpandedForm';
import FormPositionConfirm from './components/FormPositionConfirm';
import HelloDXS from '@/modules/user/HelloDXS';

import { useStore } from 'vuex';
import { watch, ref, computed, onUnmounted } from 'vue';

import { toCurrencyFormat } from '@/helpers/utils';
import { floatList } from '@/helpers/floatList';

import { workHours } from '@/helpers/utils';
import useMarketCoin from '@/compositions/useMarketCoin';
import ModalMarketOpenClose from '@/components/modals/ModalMarketOpenClose';
import ModalConnectionLost from '@/components/modals/ModalConnectionLost';
import { useI18n } from 'vue-i18n';
import notify from '@/plugins/notify';
import { PositionSides } from '@/config/constants';
import { scope } from '@/breakpoints';

import '@/assets/icons/arrowleft';
import { useScope } from '@/compositions/useScope';
import { setLocalItem } from '@/helpers/storage';
import { marketTicker } from '@/config/wrapMarkets';
import { useModals } from '@/modules/modals/api';
import { banCountires } from '@/config/countries';
import { isRunningStandaloneAndroid, isIOS } from '@/helpers/detects';
// import { updateAndSetUserSettings } from '@/modules/settings/api';
import authMixin from '@/mixins/auth.mixin';
import screenMixin from '@/mixins/screen.mixin';
import useAuth from '@/compositions/useAuth';
import {
  useFormData,
  useFormVisibility,
  usePositionForm,
} from '@/modules/formPosition/use/formComposition';
import {
  subscribeThrottledToInputFocusEvents,
  subscribeToInputFocusEvents,
} from '@/helpers/focusEvents';
import { mobileKeyboard } from '@/helpers/mobileKeyboard';

export default {
  name: 'FormPosition',
  mixins: [authMixin, screenMixin],
  components: {
    Modal,
    Card,
    FormPositionBuySell,
    FormPositionSimpleForm,
    FormPositionExpandedForm,
    FormPositionConfirm,
    ModalMarketOpenClose,
    HelloDXS,
    ModalConnectionLost,
  },
  props: {
    useMobileLayout: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const auth = useAuth();
    const { isNotDesktop } = useScope();

    const marketCoins = useMarketCoin();

    const store = useStore();
    const activeMarket = computed(() => {
      return store.getters['markets/activeMarket'] || {};
    });

    const isIOSTg = computed(() => {
      return Boolean(store.getters['telegram/instance']) && isIOS();
    });

    const showConfirmModal = ref(false);
    const showConnectionLost = ref(false);

    const backToMarkets = () => {
      emit('close');
    };

    const isWorkingMarket = computed(
      () =>
        activeMarket.value && marketCoins.isWorkingMarket(activeMarket.value)
    );

    const closedMarket = computed(() => {
      return !isWorkingMarket.value;
    });

    const { formComputed, enableToOpenPosition, maxPositionAmountUsd, form } =
      useFormData(store);
    const { set: positionFormSetters } = usePositionForm(store);

    const formExpanded = form.isExpanded;
    const tpslError = form.tpslError;

    const positionType = computed(() => {
      if (isWorkingMarket.value) {
        return form.positionType.value;
      }

      return '';
    });

    const formData = formComputed;

    const { showModal, modalsByName } = useModals();

    const { t } = useI18n();

    const workHour = ref('');
    const showMarketOpenClose = ref(false);
    const showDXSModal = ref(false);

    console.debug('#activeMarket #FormPosition', activeMarket.value);
    console.debug(
      '#activeMarketId #FormPosition',
      store.getters['markets/activeMarketId']
    );

    console.debug(
      '#activeMarket 2 #FormPosition',
      store.getters['formPosition/activeMarket']
    );

    const sleepLoading = computed(
      () => store.getters['positions/sleepLoading']
    );

    const priceBuy = computed(() =>
      toCurrencyFormat(
        activeMarket.value.buy,
        null,
        floatList[activeMarket.value.name]
      ).toString()
    );
    const priceSell = computed(() =>
      toCurrencyFormat(
        activeMarket.value.sell,
        null,
        floatList[activeMarket.value.name]
      ).toString()
    );

    const onInactiveBuySell = () => {
      showMarketOpenClose.value = true;
      workHour.value = workHours(new Date(), activeMarket.value);
    };

    // not a network connection, but a market connection
    const onConnectionLost = () => {
      showConnectionLost.value = true;
    };

    const onHidden = () => {
      form.isExpanded.value
        ? positionFormSetters.setIsExpanded(false)
        : positionFormSetters.setIsFormShown(false);
    };

    const isLong = computed(() => positionType.value.toLowerCase() === 'buy');
    const isShort = computed(() => positionType.value.toLowerCase() === 'sell');

    const orderCardDescrition = computed(() => {
      const side =
        positionType.value.toLowerCase() === 'buy' ? 'long' : 'short';

      return `${marketTicker(
        activeMarket.value.name
      )} ${side}, debiting your wallet`;
    });

    watch(
      () => sleepLoading.value,
      () => {
        if (sleepLoading.value) {
          showConfirmModal.value = false;
        }
      }
    );

    watch(
      () => auth.isLoggedIn.value,
      async () => {
        if (activeMarket?.value && auth.isLoggedIn.value) {
          await store.dispatch(
            'volumeLimits/fetchGroupActiveAmount',
            activeMarket.value.id
          );
        }
      }
    );

    watch(
      () => activeMarket?.value?.usdPrice,
      async () => {
        if (activeMarket?.value?.usdPrice) {
          window.top.document.title = `${toCurrencyFormat(
            activeMarket.value.usdPrice,
            null,
            floatList[activeMarket.value.name]
          )} ${activeMarket.value.name}`;
        }

        if (auth.isLoggedIn.value) {
          await store.dispatch(
            'volumeLimits/fetchLoyaltyFeeRate',
            activeMarket?.value?.id
          );
          await store.dispatch(
            'volumeLimits/fetchLoyaltyFeeInfo',
            activeMarket?.value?.id
          );
        }
      }
    );

    watch(
      () => activeMarket.value,
      async (newV, oldV) => {
        if (newV && oldV && newV.id === oldV.id) {
          return;
        }

        if (activeMarket?.value && auth.isLoggedIn.value) {
          await store.dispatch(
            'volumeLimits/fetchGroupActiveAmount',
            activeMarket.value.id
          );
        }
      }
    );

    const getAccountBalance = computed(
      () => store.getters['user/getAccountBalance']
    );

    const positionActionTitle = computed(() => {
      if (formData.value.entryPrice) {
        switch (positionType.value) {
          case PositionSides.BUY:
            if (
              getAccountBalance.value.bounty &&
              !bountyPaused.value &&
              +formData.value.marginUsd &&
              getAccountBalance.value.bounty >= +formData.value.marginUsd
            ) {
              return `BOUNTY AT ${t('order.new.titles.buy')} ${
                formData.value.entryPrice
              }`;
            }
            return `${t('order.new.titles.buy')} AT ${
              formData.value.entryPrice
            }`;
          case PositionSides.SELL:
            if (
              getAccountBalance.value.bounty &&
              +formData.value.marginUsd &&
              getAccountBalance.value.bounty >= +formData.value.marginUsd
            ) {
              return `BOUNTY AT ${t('order.new.titles.sell')} ${
                formData.value.entryPrice
              }`;
            }
            return `${t('order.new.titles.sell')} AT ${
              formData.value.entryPrice
            }`;
          default:
            return '';
        }
      }
      switch (positionType.value) {
        case PositionSides.BUY:
          if (
            getAccountBalance.value.bounty &&
            +formData.value.marginUsd &&
            getAccountBalance.value.bounty >= +formData.value.marginUsd
          ) {
            return `${t('order.new.titles.bountyBuy')} AT ${priceBuy.value}`;
          }
          return `${t('order.new.titles.marketBuy')} AT ${priceBuy.value}`;
        case PositionSides.SELL:
          if (
            getAccountBalance.value.bounty &&
            +formData.value.marginUsd &&
            getAccountBalance.value.bounty >= +formData.value.marginUsd
          ) {
            return `${t('order.new.titles.bountySell')} AT ${priceSell.value}`;
          }
          return `${t('order.new.titles.marketSell')} AT ${priceSell.value}`;
        default:
          return '';
      }
    });

    const isBountyPosition = computed(
      () =>
        getAccountBalance.value.bounty >= +formData.value.marginUsd &&
        auth.isFiorinMode.value
    );

    const bountyPaused = computed(
      () => store.getters['settings/uiSettings']?.bountyPaused
    );

    const showBackControls = computed(
      () => store.getters['localUiSettings/showBackControls']
    );

    const bountyTasks = computed(() => store.getters['bounty/bountyTasks']);

    const location = computed(() => store.getters['usa/getLocation']);

    const confirmOperation = async () => {
      console.debug('#confirmOperation #FormPosition', {
        formData: formData.value,
        maxPositionAmountUsd: maxPositionAmountUsd.value,
        enableToOpenPosition: enableToOpenPosition.value,
        auth: auth.isLoggedIn.value,
        isNotDesktop: isNotDesktop.value,
        location: location.value,
        banCountires: banCountires,
      });

      if (!auth.isLoggedIn.value) {
        store.dispatch('burger/login');

        if (isNotDesktop.value) {
          backToMarkets();
        }
        return;
      }

      if (
        !localStorage.getItem('usa') &&
        banCountires.includes(location.value?.countryCode)
      ) {
        showModal(modalsByName.usaAgree);
        return;
      }

      const phoneConfirmed =
        store.getters['settings/settings']?.phoneNumberConfirmed;

      if (store.getters['settings/uiSettings']?.blockedFromIp) {
        if (!phoneConfirmed) {
          store.dispatch('burger/phoneVerification', { auto: false });
          return;
        }
      }

      if (
        isBountyPosition.value &&
        bountyTasks.value.hasPositions &&
        !phoneConfirmed
      ) {
        store.dispatch('burger/phoneVerification', { auto: false });
        return;
      }

      if (!enableToOpenPosition.value) {
        notify({
          text: t('popups.marketInactive.limitReached'),
          type: 'info',
        });
        return;
      }
      if (!formData.value.amountUsd) {
        notify({
          text: 'Enter amount',
          type: 'info',
        });
        const input = document.querySelector(
          '.new-version.selectorLiquidityInput input'
        );
        if (input) {
          input.focus();
        }
        return;
      }

      if (formData.value.marginUsd < 0.01) {
        notify({
          text: t('positionform.minMargin'),
          type: 'info',
        });
        return;
      }

      if (!+formData.value.amountUsd || tpslError.value) {
        return;
      }

      if (!auth.isLoggedIn.value) {
        showDXSModal.value = true;
        return;
      }

      const maxAmountValue = activeMarket.value.max_amount_usd;

      if (!+maxAmountValue) {
        notify({
          text: t('popups.marketInactive.limitReached'),
          type: 'info',
        });
        return;
      }

      if (+formData.value.amountUsd > +maxPositionAmountUsd.value) {
        if (auth.isHandCash.value) {
          notify({
            text: t('order.new.toasts.depletedHandcash'),
            type: 'info',
          });
          return;
        }
        notify({
          text: t('order.new.toasts.depleted', {
            provider: auth.provider.value,
          }),
          type: 'info',
        });
        return;
      }

      {
        if (
          !isBountyPosition.value &&
          !store.getters['settings/settings'].emailConfirmed
        ) {
          const uiSettings = store.getters['settings/uiSettings'];
          const countLater = uiSettings.emailVerificationLater || 0;

          const allowed = countLater <= 9;

          console.debug(
            '#formposition #emailconfirm',
            'countLater:',
            countLater,
            'allowed:',
            allowed
          );

          await showModal(modalsByName.email);
          if (!allowed) {
            if (!store.getters['settings/settings'].emailConfirmed) {
              return;
            }
          }
        }
      }

      showConfirmModal.value = true;
    };

    const onPayment = async () => {
      try {
        showConfirmModal.value = false;

        const setBreakInfo = () => {
          if (
            formData.value.entryPrice &&
            !localStorage.getItem('showBreakOutTradeModal')
          ) {
            showModal(modalsByName.breakoutTrade);
          }
        };

        if (
          +activeMarket.value.usdPrice < +formData.value.entryPrice &&
          positionType.value === PositionSides.BUY
        ) {
          setBreakInfo();
        } else if (
          +activeMarket.value.usdPrice > +formData.value.entryPrice &&
          positionType.value !== PositionSides.BUY
        ) {
          setBreakInfo();
        }

        await store.dispatch('formPosition/resetAllButLeverageAndType');

        store.dispatch('bounty/updateBalance');

        if (isNotDesktop.value) {
          setLocalItem('mobileScrollPositions.Positions', {
            top: 0,
            left: 0,
          });
          emit('payment');
        }
      } catch (e) {
        console.error('#onPayment #FormPosition error:', e);
      }
    };

    const onClose = () => {
      showConfirmModal.value = false;
    };

    const onError = () => {
      showConfirmModal.value = false;
    };

    if (props.useMobileLayout) {
      const getContainer = () => document.querySelector('.form-position');

      const unsubscribeInputFocusEvents = subscribeToInputFocusEvents(
        'formPosition',
        () => {
          if (mobileKeyboard.isAvailable) {
            mobileKeyboard.disableBuiltinBehavior();
          }
        },
        () => {}
      );

      let timerId;
      const unsubscribeInputFocusEventsThrottled =
        subscribeThrottledToInputFocusEvents(
          'formPosition',
          () => {
            console.debug('#formposition onFocusedThrottled');
            if (!mobileKeyboard.isAvailable) {
              let container = getContainer();
              let topPosition =
                window.visualViewport.height -
                container.getBoundingClientRect().height;
              container.style.position = 'fixed';
              container.style.top = topPosition + 'px';

              console.debug(
                '#formposition disableBuiltinBehavior',
                'topPosition:',
                topPosition
              );

              timerId = setTimeout(() => {
                topPosition =
                  window.visualViewport.height -
                  container.getBoundingClientRect().height;
                if (topPosition + 'px' !== container.style.top) {
                  container.style.top = topPosition + 'px';
                }
              }, 300);
            }
          },
          () => {
            clearTimeout(timerId);
            console.debug('#formposition onFocusOutThrottled');

            if (!mobileKeyboard.isAvailable) {
              let container = getContainer();
              container.style.position = 'absolute';
              container.style.bottom = '2px';
              container.style.top = '';
            }
          }
        );

      const setBackButtonVisible = (visible) => {
        if (store.getters['localUiSettings/showBackControls'] === visible) {
          return;
        }

        setTimeout(() => {
          store.dispatch('localUiSettings/setShowBackControls', visible);
        }, 10);
      };

      const unsubsribeMobileKeyboard = mobileKeyboard.subscribeToKeyboardEvents(
        (height) => {
          console.debug('#formposition #keyboard show');
          let container = getContainer();
          container.style.bottom = height + 'px';
        },
        () => {
          console.debug('#formposition #keyboard hide');
          let container = getContainer();
          container.style.bottom = '2px';
          mobileKeyboard.enableBuiltinBehavior();

          // blur the textbox if keyboard got hidden
          if (
            document.activeElement &&
            document.activeElement.tagName.toUpperCase() === 'INPUT' &&
            document.activeElement.type.toUpperCase() === 'TEXT' &&
            typeof document.activeElement.blur === 'function'
          ) {
            document.activeElement.blur();
          }
        }
      );

      onUnmounted(() => {
        setTimeout(() => {
          {
            console.debug('#formposition onUnmounted');

            mobileKeyboard.enableBuiltinBehavior();
            if (unsubscribeInputFocusEvents) {
              unsubscribeInputFocusEvents();
            }

            if (unsubscribeInputFocusEventsThrottled) {
              unsubscribeInputFocusEventsThrottled();
            }

            if (unsubsribeMobileKeyboard) {
              unsubsribeMobileKeyboard();
            }
            setBackButtonVisible(true);
          }
        }, 1000);
      });
    }

    const { isFormShown } = useFormVisibility(store);

    return {
      isFormShown,
      formData,
      isLong,
      isShort,
      showBackControls,
      isWorkingMarket,
      positionType,
      formExpanded,
      positionActionTitle,
      activeMarket,
      priceBuy,
      priceSell,
      orderCardDescrition,
      showConfirmModal,

      tpslError,
      confirmOperation,
      scope,

      isBountyPosition,
      bountyPaused,

      onPayment,
      onError,
      onClose,

      closedMarket,
      showDXSModal,
      showConnectionLost,
      showMarketOpenClose,
      onInactiveBuySell,
      onConnectionLost,
      onHidden,
      workHour,

      backToMarkets,
      wd: window,
      isRunningStandaloneAndroid,
      marketTicker,
      isIOSTg,
      isIOS,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/base';
@import '@/assets/styles/colors';

.form-position {
  &__controls {
    display: flex;
  }

  &__controls-btn {
    height: 60px;
    width: 100%; // calc(100% - 50px);
    color: $color-white;
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;
    text-transform: uppercase;
    font-size: 0.9375rem;
    font-family: Cantarell_Bold;
    display: flex;
    align-items: center;
    justify-content: center;

    &.open {
      // padding-left: 35px;
    }

    &.mobile {
      width: 100%; // calc(100% - 100px);
      // padding-right: 22px;
    }

    &:hover {
      cursor: pointer;
      opacity: 1;
    }

    &.disabled {
      opacity: 0.7;
    }

    &.toggle {
      width: 50px;
      font-family: Gotham_Pro_Medium;
      font-size: 0.65rem;
    }

    &.back {
      width: 50px;
      font-family: Gotham_Pro_Medium;
      font-size: 0.65rem;
    }
  }
}

.form-position.useMobileLayout {
  position: absolute;
  bottom: 2px;
  z-index: 1000;
  width: 100%;

  &.isTelegram {
    top: 80px;
    bottom: initial;
  }

  &.standalone {
    bottom: 30px;

    &.showBackControls {
      bottom: 0;
    }
  }

  &.showBackControls {
    bottom: 0;
  }
}
</style>
