import { WalletNames } from '@/wallets/common';

const getInitialState = () => ({
  // walletLoading: true,
  // connectionStatus: true,
  // authLoader: false,
  // isReconnect: false,
});

let intervalId = null;

const assertLoggedIn = (rootGetters, methodName) => {
  if (!rootGetters['auth/isLoggedIn']) {
    console.debugError(
      `Accessing the ${methodName} when not authenticated  #wallet #getters #ifLoggedIn`
    );

    throw new Error('Not logged in');
  }
};

const getters = {
  // userName: (state) => state.userName,
  paymail: (state, getters, rootState, rootGetters) => {
    assertLoggedIn(rootGetters, 'paymail');
    let provider = rootGetters['auth/provider'];
    let ret;
    switch (provider) {
      // case userProviders.dotWallet:
      //   // eslint-disable-next-line no-case-declarations
      //   let uname = getters.userName;
      //   ret = uname + '@dotwallet.com';
      //   break;
      case WalletNames.fiorin:
        ret = rootGetters['fiorin/paymail'];
        break;
      case WalletNames.handCash: {
        ret = rootGetters['handcash/paymail'];
        break;
      }
      default:
        break;
    }
    return ret;
  },

  userFullName: (state, getters, rootState, rootGetters) => {
    if (!rootGetters['auth/isLoggedIn']) {
      console.debugError(
        'Accessing the #userFullName when not authenticated  #wallet #getters #ifLoggedIn'
      );
      return null;
    }

    if (rootGetters['auth/isFiorin']) {
      const fiorinUserName = rootGetters['fiorin/userName'];
      return fiorinUserName.toLowerCase();
    }

    const provider = rootGetters['auth/provider'];

    if (provider === WalletNames.handCash) {
      return rootGetters['handcash/fullName'];
    }

    if (provider === WalletNames.dotWallet) {
      // const userInfo = this.activeConnect && this.activeConnect.userInfo;
      // if (userInfo && userInfo.id) {
      //   return `${userInfo.id}`;
      //   // return `${userInfo.id}@DW`;
      // }
    }

    console.debugError(
      'Unsupported provider when getting fullName #wallet #getters #userPaymail',
      'provider:',
      provider
    );
  },

  userName: (state, getters, rootState, rootGetters) => {
    assertLoggedIn(rootGetters, 'userName');

    if (rootGetters['auth/isFiorin']) {
      const fiorinUserName = rootGetters['fiorin/userName'];
      return fiorinUserName?.split('@')[0]?.toLowerCase();
    }

    const provider = rootGetters['auth/provider'];

    if (provider === WalletNames.handCash) {
      return rootGetters['handcash/userName'];
    }

    if (provider === WalletNames.dotWallet) {
      // const userInfo = this.activeConnect && this.activeConnect.userInfo;
      // if (userInfo && userInfo.id) {
      //   return `${userInfo.id}`;
      //   // return `${userInfo.id}@DW`;
      // }
    }

    console.debugError(
      'Unsupported provider when getting userName #wallet #getters #userPaymail',
      'provider:',
      provider
    );
  },

  bountyBalanceUsd: (state, getters, rootState, rootGetters) => {
    assertLoggedIn(rootGetters, 'bountyBalanceUsd');

    if (rootGetters['auth/isFiorin']) {
      return rootGetters['fiorin/bountyBalanceUsd'];
    } else if (rootGetters['auth/isHandCash']) {
      return 0;
    } else {
      console.debugError(
        'Unsupported provider when getting bountyBalance #wallet #getters #bountyBalance',
        'provider:',
        rootGetters['auth/provider']
      );
      return 0;
    }
  },

  totalAssetsAmountUsd: (state, getters, rootState, rootGetters) => {
    assertLoggedIn(rootGetters, 'activeTokenAmountUsd');

    if (rootGetters['auth/isFiorin']) {
      return rootGetters['fiorin/totalAssetsAmountUsd'];
    } else if (rootGetters['auth/isHandCash']) {
      return rootGetters['handcash/amountUsd'];
    } else {
      console.debugError(
        'Unsupported provider when getting #activeTokenAmountUsd #wallet #getters',
        'provider:',
        rootGetters['auth/provider']
      );
      return 0;
    }
  },

  pendingUsd: (state, getters, rootState, rootGetters) => {
    assertLoggedIn(rootGetters, 'pendingUsd');

    if (rootGetters['auth/isFiorin']) {
      return rootGetters['fiorin/pendingUsd'];
    }

    return 0;
  },
};

const mutations = {};

let actions = {
  async fetchBalance({ rootGetters, dispatch }) {
    if (!rootGetters['auth/isLoggedIn']) {
      return;
    }

    if (rootGetters['auth/isFiorin']) {
      await dispatch('fiorin/fetchBalance', null, { root: true });
    } else if (rootGetters['auth/isHandCash']) {
      await dispatch('handcash/fetchBalance', null, { root: true });
    } else {
      console.debugError(
        'Unsupported provider when fetching balance #wallet #actions #fetchBalance',
        'provider:',
        rootGetters['auth/provider']
      );
    }
  },

  // todo: [Vadim] remove at all?
  async fetchBountyBalance({ rootGetters }) {
    if (rootGetters['auth/isFiorin']) {
      // nothing to do, it gets updated automatically from fiorin
    } else if (rootGetters['auth/isHandCash']) {
      // nothing to do
    } else {
      console.debugError(
        'Unsupported provider when fetching bounty balance #wallet #actions #fetchBountyBalance',
        'provider:',
        rootGetters['auth/provider']
      );
    }
  },

  onAuthenticated({ rootGetters, dispatch }) {
    intervalId = setInterval(() => {
      if (rootGetters['auth/isLoggedIn']) {
        dispatch('fetchBalance');
      }
    }, 60_000);
  },

  async logout({ rootGetters, dispatch }) {
    if (intervalId) {
      clearInterval(intervalId);
    }

    if (rootGetters['auth/isFiorin']) {
      await dispatch('fiorin/logout', null, { root: true });
    } else if (rootGetters['auth/isHandCash']) {
      await dispatch('handcash/logout', null, { root: true });
    } else {
      console.debugError(
        'Unsupported provider when logging out #wallet #actions #logout',
        'provider:',
        rootGetters['auth/provider']
      );
    }
  },

  async clear({ dispatch }) {
    console.debug('#wallet #actions #clear begin');
    await Promise.all([
      dispatch('fiorin/clear', null, { root: true }),
      dispatch('handcash/clear', null, { root: true }),
    ]);
    console.debug('#wallet #actions #clear END');
  },
};

export default {
  namespaced: true,
  state: getInitialState,
  getters,
  mutations,
  actions,
};
