const types = {
  ADD_NOTIFICATION: 'SET_NOTIFICATION',
  REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
};

export default {
  namespaced: true,
  state: () => ({
    notifications: [],
  }),

  getters: {
    notifications: (state) => state.notifications,
  },

  mutations: {
    [types.ADD_NOTIFICATION](state, notification) {
      if (state.notifications.find((n) => n.text === notification.text)) {
        return;
      }

      if (notification.group) {
        const sameGroupIndex = state.notifications.findIndex(
          (n) => n.group === notification.group
        );

        if (sameGroupIndex === -1) {
          state.notifications.unshift(notification);
        } else {
          const existingNotification = state.notifications[sameGroupIndex];

          clearTimeout(existingNotification.timeoutId);
          const timeoutId = setTimeout(
            notification.remove,
            notification.duration
          );

          state.notifications.splice(sameGroupIndex, 1, {
            ...existingNotification,
            text: notification.text,
            duration: notification.duration,
            timeoutId,
          });
        }
      } else {
        state.notifications.unshift(notification);
      }
    },
    [types.REMOVE_NOTIFICATION](state, id) {
      const index = state.notifications.findIndex((n) => n.id === id);
      state.notifications.splice(index, 1);
    },
  },

  actions: {
    async addNotification({ commit, dispatch }, params) {
      console.debug(
        '#addNotification #store #notifications #actions id:',
        params.id,
        'params:',
        params
      );

      const timeoutId = setTimeout(
        () => dispatch('removeNotification', params.id),
        params.duration
      );

      commit(types.ADD_NOTIFICATION, {
        ...params,
        timeoutId,
        remove: () => dispatch('removeNotification', params.id),
      });
    },

    removeNotification({ commit }, id) {
      commit(types.REMOVE_NOTIFICATION, id);
    },
  },
};
