<template>
  <Modal :stop-mobile-max-height="true" @close="$emit('close')">
    <div class="sub-cent-wrap">
      <Card :title="$t('popups.subcent.title')" class="card">
        <div class="sub-cent">
          <div class="sub-cent__info mv-20">
            {{ $t('popups.subcent.text') }}
          </div>
        </div>
      </Card>
      <div class="btns">
        <Button
          :text="$t('popups.buttons.learnMore')"
          type="secondary"
          class="btn"
          @click="redirectToFaq"
        />
      </div>
    </div>
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';

export default {
  components: { Modal, Card, Button },
  methods: {
    redirectToFaq() {
      window.open(
        'https://faq.dxs.app/trading/trade-sizes#what-about-profits-less-than-1c',
        '_blank'
      );

      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.sub-cent-wrap {
  .card {
    min-height: 240px;
  }

  .sub-cent {
    &__info {
      font-family: Gotham_Pro_Regular;
      font-size: 0.9375rem;
      color: $color-gray;
      line-height: $default-lineheight;

      &.mv-20 {
        margin-top: 20px;
        margin-bottom: 75px;
      }
    }
  }

  .btn {
    margin-top: 30px;
    margin-left: 1px;
    margin-right: 1px;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .btns {
    width: 100%;
    margin-top: 50px;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
